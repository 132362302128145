@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

.main_container {
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    font-family: 'Josefin Sans', sans-serif;
}

.main_container .header_area {
    height: 25%;
    width: 100%;
    background-color: #20BF84;
    display: flex;
    margin-bottom: 0%;
}

.header_area .user_area {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding-left: 3%;
}

.user_area .profile_img {
    height: 75%;
    /* border: 2px solid red; */
    border-radius: 50%;
}

.user_area .profile_img img {
    height: 100%;
    object-fit: contain;
}

.user_area .username_area h2,
.user_area .username_area h4 {
    margin-block-start: 0%;
    margin-block-end: 0%;
}

.stats_area {
    width: 40%;
    color: #ffffff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.stats_area h1,
.stats_area h5 {
    margin-block-start: 0%;
    margin-block-end: 0%;
}

.main_container .achievements_area {
    height: 75%;
    width: 100%;
    margin-top: 0%;
    background: #000428;
    background: -webkit-linear-gradient(to right, #004e92, #000428);
    background: linear-gradient(to right, #004e92, #000428);
}

.achievements_area h1 {
    padding-left: 3%;
    width: 100%;
    margin-block-start: 0%;
    padding-top: 2%;
    color: #ffffff;
    margin-bottom: 15px;
}

.achievements_area .cards_container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.loading_container {
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px) {
    .main_container {
        overflow: scroll;
        overflow-x: hidden;
    }

    .main_container .header_area {
        flex-direction: column;
    }

    .header_area .user_area {
        height: 50%;
        width: 100%;
    }

    .main_container .achievements_area {
        height: auto;
    }

    .achievements_area .cards_container {
        flex-direction: column;
        padding: 0% 7%;
    }

    .stats_area {
        width: 100%;
    }

    .stats_area h1 {
        font-size: 24px;
    }

    .stats_area h5 {
        font-size: 18px;
    }
}