@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');

/* LOADING SCREEN */
#loading_screen {
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
}
#loading_screen  img{
    width: 100%;
}

/* VALID USER SCREEN */
#user_authenticated {
    width: 100%;
    background-color: #ffffff;
    scroll-behavior: smooth;
    min-height: 100vh;
}

#user_authenticated .question_area{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
}

#user_authenticated .question_area .suggestions_area{
    display: flex;
    justify-content: space-between;
}

#user_authenticated .question_area .suggestions_area .suggestions {
    background-color: #a5a5a5;
    color: #ffffff;
    border: none;
    padding: 2%;
    border-radius: 10px;
    margin: 2%;
    cursor: pointer;
}

#user_authenticated .question_area .suggestions_area .suggestions:hover {
    background-color: #00B495;
}

#user_authenticated .question_area .ask_question{
    width: 100%;
    padding: 1% 2%;
    display: flex;
}

#user_authenticated .question_area .ask_question #user_question{
    border: 2px solid #C5C5C5;
    border-radius: 10px;
    width: 70%;
    font-size: 15px;
    padding: 3% 5%;
}

#user_authenticated .question_area .ask_question #send_question{
    /* margin: 2% 4% 2% 0%; */
    margin-left: 5%;
    width: 10%;
    background-color: #00B495;
    border: none;
    outline: none;
    border-radius: 10px;
    color: #ffffff;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

#user_authenticated .chat_area {
    padding: 4% 3%;
    font-family: 'Quicksand', sans-serif;
}

#user_authenticated .chat_area .single_chat_area{
    margin-bottom: 2%;
}

#user_authenticated .chat_area .user_chat_area{
    display: flex;
    justify-content: flex-end;
}

#user_authenticated .chat_area .bot_chat_area{
    display: flex;
    justify-content: flex-start;
}

#user_authenticated .chat_area .single_chat_area .single_chat {
    width: 70%;
    padding: 3%;
}

#user_authenticated .chat_area .single_chat_area .userChat {
    background-color: #CAF0F5;
    color: #000000;
    border-radius: 10px 10px 0px 10px;
}

#user_authenticated .chat_area .single_chat_area .botChat {
    background-color: #00707D;
    color: #ffffff;
    border-radius: 10px 10px 10px 0px;
}

#user_authenticated .empty_space {
    height: 25vh;
}

#user_authenticated .question_area .ask_question #send_question:hover {
    cursor: pointer;
    background-color: #C5C5C5;
    color: #00B495;
}

#user_authenticated .typing_area {
    width: 80%;
    padding-left: 3%;
}

#user_authenticated .typing_area .typing_img {
    width: 15%;
}

/* INVALID USER SCREEN */
#invalid_user {
    margin-top: -6%;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
}

#invalid_user .error_heading {
    width: 100%;
}

#invalid_user .error_heading h1{
    text-align: center;
    padding: 5%;
    font-size: 2rem;
}

#invalid_user .error_img {
    width: 100%;
}

#invalid_user .error_img img{
    width: 100%;
}