@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

.deleteUserContainer {
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
}

.deleteUserContainer .delete_page_card {
    margin-top: 3%;
    background-color: #ffffff;
    width: 70%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 20px 50px 0 rgba(0, 0, 0, .1);
    border-radius: 15px;
    padding: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #575757;
    font-family: 'Nunito Sans', sans-serif;
}

.deleteUserContainer .delete_page_card .delete_page_head {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 3%;
}

.deleteUserContainer .delete_page_card .delete_page_head span {
    font-size: 18px;
}

.deleteUserContainer .delete_page_card .delete_page_head img {
    width: 20%;
    margin: 0.5% 0%;
}

.deleteUserContainer .delete_page_card .delete_page_head .delete_page_brand_logo {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    /* background-color: aqua; */
}

.deleteUserContainer .delete_page_card .delete_page_head .delete_page_brand_logo .brand_logo_box {
    width: 43px;
    height: 43px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 15px;
}

.deleteUserContainer .delete_page_card .delete_page_head .delete_page_brand_logo .brand_logo_box img {
    width: 100%;
}

.deleteUserContainer .delete_page_card .delete_page_head .delete_page_brand_logo h3 {
    font-size: 40px;
    color: #45977F;
} 

.deleteUserContainer .delete_page_card .delete_page_form_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_email {
    width: 30%;
    border: #575757 1px solid;
    border-radius: 20px;
    padding: 1% 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_password {
    width: 30%;
    border: #575757 1px solid;
    border-radius: 20px;
    padding: 1% 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_email svg {
    width: 10%;
    font-size: 20px;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_password svg {
    width: 10%;
    font-size: 20px;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_email input {
    width: 90%;
    border: none;
    outline: none;
    font-size: 15px;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_password input {
    width: 90%;
    border: none;
    outline: none;
    font-size: 15px;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_email input:focus {
    border: none;
    outline: none;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_password input:focus {
    border: none;
    outline: none;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit {
    width: 100%;
    text-align: center;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit button {
    width: 34%;
    padding: 1%;
    font-size: 17px;
    background-color: #e64a19;
    border: none;
    border-radius: 20px;
    color: #ffffff;
    cursor: pointer;
    border: 1px solid #e64a19;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #zenze_delete_btn {
    background-color: #45977F;
    border: 1px solid #45977F;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #dtox_delete_btn {
    background-color: #F53E3E;
    border: 1px solid #F53E3E;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #healify_delete_btn {
    background-color: #95CA61;
    border: 1px solid #95CA61;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #socialx_delete_btn {
    background-color: #0C47A1;
    border: 1px solid #0C47A1;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #website_blocker_delete_btn {
    background-color: #803FF1;
    border: 1px solid #803FF1;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #app_blocker_delete_btn {
    background-color: #3D5AFE;
    border: 1px solid #3D5AFE;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #quit_porn_delete_btn {
    background-color: #E32E58;
    border: 1px solid #E32E58;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #parental_control_delete_btn {
    background-color: #3D5AFE;
    border: 1px solid #3D5AFE;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #content_blocker_delete_btn {
    background-color: #F28F0E;
    border: 1px solid #F28F0E;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit button:hover {
    background-color: #ffffff;
    color: #e64a19;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #zenze_delete_btn:hover {
    background-color: #ffffff;
    color: #45977F;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #dtox_delete_btn:hover {
    background-color: #ffffff;
    color: #F53E3E;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #healify_delete_btn:hover {
    background-color: #ffffff;
    color: #95CA61;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #socialx_delete_btn:hover {
    background-color: #ffffff;
    color: #0C47A1;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #website_blocker_delete_btn:hover {
    background-color: #ffffff;
    color: #803FF1;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #app_blocker_delete_btn:hover {
    background-color: #ffffff;
    color: #3D5AFE;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #quit_porn_delete_btn:hover {
    background-color: #ffffff;
    color: #E32E58;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #parental_control_delete_btn:hover {
    background-color: #ffffff;
    color: #3D5AFE;
}

.deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit #content_blocker_delete_btn:hover {
    background-color: #ffffff;
    color: #F28F0E;
}

.deleteUserContainer .delete_page_card .delete_page_partition {
    font-size: 20px;
    margin: 2% 0%;
}

.deleteUserContainer .delete_page_card .delete_page_google_area {
    width: 100%;
    display: flex;
    justify-content: center;
}

.deleteUserContainer .delete_page_card .delete_page_google_area button {
    width: 34%;
    border: 1px solid #000000;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7%;
    font-size: 17px;
    cursor: pointer;
}

.deleteUserContainer .delete_page_card .delete_page_google_area button:hover {
    background-color: #ffffff;
}

.deleteUserContainer .delete_page_card .delete_page_google_area button span {
    margin-right: 7px;
}

.delete_confirmation_popup {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #26262648;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete_confirmation_popup .delete_confirmation_card {
    background: #ffffff;
    border: #575757 1px solid;
    width: 350px;
    padding: 2%;
    border-radius: 5px;
    color: #575757;
}

.delete_confirmation_popup .delete_confirmation_card h1 {
    font-size: 22px;
}

.delete_confirmation_popup .delete_confirmation_card h1 svg {
    color: #e64a19;
}

.delete_confirmation_popup .delete_confirmation_card span {
    font-size: 15px;
}

.delete_confirmation_popup .delete_confirmation_card p {
    font-size: 15px;
    margin: 10px 0px;
}

.delete_confirmation_popup .delete_confirmation_card .delete_confirmation_btn_area {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.delete_confirmation_popup .delete_confirmation_card .delete_confirmation_btn_area button {
    background: none;
    border: none;
    outline: none;
    font-size: 18px;
    cursor: pointer;
    margin-right: 2%;
    padding: 2% 4%;
    border-radius: 3px;
}

.delete_confirmation_popup .delete_confirmation_card .delete_confirmation_btn_area button:hover {
    background: #eaeaea;
}

.delete_confirmation_popup .delete_confirmation_card .delete_confirmation_btn_area .delete_confirmation_submit {
    background-color: #e64a19;
    color: #ffffff;
}

.delete_confirmation_popup .delete_confirmation_card .delete_confirmation_btn_area .delete_confirmation_submit:hover {
    color: #000000;
}

@media (max-width: 1000px) {
    .deleteUserContainer .delete_page_card {
        width: 80%;
    }

    .deleteUserContainer .delete_page_card .delete_page_head img {
        width: 30%;
    }

    .deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_email {
        width: 40%;
    }

    .deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_password {
        width: 40%;
    }

    .deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit button {
        width: 44%;
    }

    .deleteUserContainer .delete_page_card .delete_page_google_area button {
        width: 44%;
    }
}

@media (max-width: 600px) {
    .deleteUserContainer .delete_page_card {
        width: 80%;
    }

    .deleteUserContainer .delete_page_card .delete_page_head img {
        width: 45%;
    }

    .deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_email {
        width: 60%;
        padding: 2% 3%;
    }

    .deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_password {
        width: 60%;
        padding: 2% 3%;
    }

    .deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit button {
        width: 64%;
        padding: 3% 4%;
    }

    .deleteUserContainer .delete_page_card .delete_page_google_area button {
        width: 64%;
        padding: 2%;
    }
}

@media (max-width: 480px) {
    .deleteUserContainer .delete_page_card {
        width: 80%;
    }

    .deleteUserContainer .delete_page_card .delete_page_head img {
        width: 55%;
    }

    .deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_email {
        width: 70%;
    }

    .deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_password {
        width: 70%;
    }

    .deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit button {
        width: 74%;
    }

    .deleteUserContainer .delete_page_card .delete_page_google_area button {
        width: 74%;
    }
}

@media (max-width: 400px) {
    .deleteUserContainer .delete_page_card {
        width: 80%;
    }

    .deleteUserContainer .delete_page_card .delete_page_head img {
        width: 65%;
    }

    .deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_email {
        width: 80%;
    }

    .deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_password {
        width: 80%;
    }

    .deleteUserContainer .delete_page_card .delete_page_form_area .delete_page_submit button {
        width: 84%;
    }

    .deleteUserContainer .delete_page_card .delete_page_google_area button {
        width: 84%;
    }
}