.benifit_simple_card {
    width: 350px;
    height: 450px;
    background-color: #FCCACA;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.benifit_simple_card .benifit_simple_card_img_area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5%;
}

.benifit_simple_card .benifit_simple_card_img_area img {
    width: 90%;
}

.benifit_simple_card .benifit_simple_card_text_area {
    width: 100%;
    height: 75px;
    background-color: #FF6B6B;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.benifit_simple_card .benifit_simple_card_text_area span {
    width: 90%;
    font-size: 23px;
}