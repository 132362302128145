#quiz_question_9 #embed_quiz_result .quiz_result_inner .quiz_result_avg {
    width: 39%;
    padding: 1% 3%;
}

#quiz_question_9 #embed_quiz_result .quiz_result_inner .quiz_result_user {
    width: 39%;
    padding: 1% 3%;
}

.quiz_questionare .close_area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.quiz_questionare .close_area .close_btn_quiz {
    font-size: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    cursor: pointer;
}

.quiz_questionare .close_area .close_btn_quiz:hover {
    color: #a8a8a8;
}

.quiz_sidebar .close_area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.quiz_sidebar .close_area .close_btn_quiz {
    font-size: 30px;
    margin-left: 30px;
    margin-top: 30px;
    cursor: pointer;
}

.quiz_sidebar .close_area .close_btn_quiz:hover {
    color: #a8a8a8;
}

.quiz_premium_end .close_area {
    width: 100%;
    justify-content: flex-end;
    display: none;
}

.quiz_premium_end .close_area .close_btn_quiz {
    font-size: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    cursor: pointer;
}

.quiz_premium_end .close_area .close_btn_quiz:hover {
    color: #a8a8a8;
}

@media (max-width: 600px) {
    .quiz_premium_end .close_area {
        display: flex;
    }
}