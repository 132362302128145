.premiumPage {
    width: 100%;
    background: #ffffff;
}

#premiumPageHeader {
    width: 80%;
    padding: 2% 10%;
    text-align: left;
}

#premiumPageHeader img {
    width: 15%;
}

#premiumPageHeroArea {
    display: flex;
    width: 80%;
    padding: 0% 10%;
    /* margin: 1% 0%;  */
}

#premiumPageHeroArea .premium_hero_data {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

#premiumPageHeroArea .premium_hero_data .hero_data_headings h1 {
    font-size: 56px;
}

#premiumPageHeroArea .premium_hero_data .hero_data_headings h1 strong {
    color: #007BFF;
}

#premiumPageHeroArea .premium_hero_data .hero_data_subheading {
    margin-top: 4%;
    margin-bottom: 4%;
    width: 90%;
}

#premiumPageHeroArea .premium_hero_data .hero_data_subheading p {
    color: #333;
    font-weight: 400;
    font-size: 25px;
}

#premiumPageHeroArea .premium_hero_data .hero_data_cta_button {
    width: 100%;
}

#premiumPageHeroArea .premium_hero_data .hero_data_cta_button button {
    background-color: #007BFF;
    padding: 2%;
    border: none;
    color: #ffffff;
    border-radius: 10px;
    width: 30%;
    font-weight: 550;
    font-size: 20px;
    cursor: pointer;
}

#premiumPageHeroArea .premium_hero_data .hero_data_cta_button button:hover {
    background-color: #84b9f2;
    color: #333;
}

#premiumPageHeroArea .premium_hero_bg_img {
    width: 50%;
}

#premiumPageHeroArea .premium_hero_bg_img img {
    width: 100%;
}

#premiumPagePricingArea {
    width: 100%;
    margin-top: 30px;
    background-color: #E8F3FF;
    display: flex;
    justify-content: center;
}

#premiumPagePricingArea .premium_pricing_inner {
    width: 50%;
    text-align: center;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_head {
    margin-top: 3%;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_head img {
    width: 10%;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_head h2 {
    color: #007BFF;
    font-size: 39px;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container {
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
    margin-bottom: 5%;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple {
    width: 41%;
    background-color: #ffffff;
    padding: 0% 4%;
    border-radius: 15px;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container #lifetime_plan_card_simple {
    background-color: #007BFF;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_tag {
    color: #ffffff;
    display: flex;
    justify-content: center;
    height: 23px;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_tag span {
    background-color: #333;
    padding: 1% 5%;
    font-size: 12px;
    border-radius: 0px 0px 8px 8px;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_plan {
    font-weight: 600;
    color: #5c5c5c;
    font-size: 22px;
    margin-top: 2%;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container #lifetime_plan_card_simple .simple_card_plan {
    color: #F0F0F0;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing h2 {
    margin-right: 5px;
    font-size: 40px;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container #lifetime_plan_card_simple .simple_card_pricing h2 {
    color: #ffffff;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing span {
    color: #696969;
    font-size: 19px;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing_info {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing_info p {
    width: 60%;
    font-size: 19px;
    color: #333;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container #lifetime_plan_card_simple .simple_card_pricing_info p {
    color: #e2e2e2;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing_info p strike {
    color: #007BFF;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container #lifetime_plan_card_simple .simple_card_pricing_info p strike {
    color: #333;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pointers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pointers span {
    font-size: 18px;
    margin-bottom: 2%;
    margin-left: 10%;
    color: #656565;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container #lifetime_plan_card_simple .simple_card_pointers span {
    color: #F0F0F0;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pointers span svg {
    width: 30px;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_button_area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10%;
}

#premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_button_area button {
    margin-left: 13%;
    width: 70%;
    border: #007BFF 1px solid;
    border-radius: 15px;
    font-weight: 700;
    background-color: #ffffff;
    color: #007BFF;
    font-size: 18px;
    padding: 4%;
    cursor: pointer;
}

#premiumPageFreedomArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 3%;
}

#premiumPageFreedomArea .freedom_container_head {
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2%;
}

#premiumPageFreedomArea .freedom_container_head h2 {
    font-size: 50px;
}

#premiumPageFreedomArea .freedom_container_head h2 strong {
    color: #007BFF;
}

#premiumPageFreedomArea .freedom_container_head p {
    font-size: 25px;
    width: 80%;
}

#premiumPageFreedomArea .freedom_container_pointers {
    width: 100%;
    display: flex;
    justify-content: center;
}

#premiumPageFreedomArea .freedom_container_pointers img {
    width: 60%;
}

#premiumPageChainArea {
    background: #E8F3FF;
    padding: 3% 1%;
    text-align: center;
}

#premiumPageChainArea h2 {
    font-size: 48px;
    color: #333;
    margin-bottom: 3%;
}

#premiumPageFirstStepArea {
    display: flex;
    justify-content: center;
}

#premiumPageFirstStepArea .first_step_container {
    width: 74%;
    text-align: center;
    margin: 3% 0% 5% 0%;
}

#premiumPageFirstStepArea .first_step_container h2 {
    color: #007BFF;
    font-size: 60px;
    margin-bottom: 2%;
}

#premiumPageFirstStepArea .first_step_container p {
    color: #333;
    font-size: 25px;
}

#premiumPageControlArea {
    background-color: #00B894;
    width: 70%;
    padding: 2% 15%;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
}

#premiumPageControlArea .control_content_area {
    width: 60%;
}

#premiumPageControlArea .control_content_area .control_description {
    margin-bottom: 2%;
}

#premiumPageControlArea .control_content_area .control_description p {
    font-size: 27px;
}

#premiumPageControlArea .control_content_area .control_description p strong {
    font-size: 35px;
}

#premiumPageControlArea .control_content_area .control_pointers {
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
}

#premiumPageControlArea .control_content_area .control_pointers span {
    font-size: 30px;
    font-weight: 600;
}

#premiumPageControlArea .control_content_area .control_cta_container {
    width: 100%;
}

#premiumPageControlArea .control_content_area .control_cta_container button {
    width: 40%;
    background-color: #007BFF;
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    border-radius: 15px;
    border: none;
    padding: 3%;
    cursor: pointer;
}

#premiumPageControlArea .control_content_area .control_cta_container button:hover {
    background-color: #ffffff;
    color: #333;
}

#premiumPageControlArea .control_img_area {
    width: 40%;
    display: flex;
    align-items: flex-end;
}

#premiumPageControlArea .control_img_area img {
    width: 100%;
}

#premiumPageDownloadsArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2%;
    margin-top: 3%;
}

#premiumPageDownloadsArea .downloads_head_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

#premiumPageDownloadsArea .downloads_head_container img {
    width: 15%;
    padding-bottom: 1%;
    border-bottom: #333 1px solid;
}

@media (max-width: 1700px) {
    #premiumPageHeroArea .premium_hero_data .hero_data_headings h1 {
        font-size: 46px;
    }

    #premiumPageHeroArea .premium_hero_data .hero_data_subheading p {
        font-size: 20px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_head h2 {
        font-size: 35px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_plan {
        font-size: 18px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing h2 {
        font-size: 35px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing span {
        font-size: 15px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing_info p {
        font-size: 15px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pointers span {
        font-size: 13px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_button_area button {
        font-size: 13px;
    }

    #premiumPageFreedomArea .freedom_container_head h2 {
        font-size: 40px;
    }

    #premiumPageFreedomArea .freedom_container_head p {
        font-size: 20px;
    }

    #premiumPageChainArea h2 {
        font-size: 40px;
    }

    #premiumPageFirstStepArea .first_step_container h2 {
        font-size: 50px;
    }

    #premiumPageFirstStepArea .first_step_container p {
        font-size: 20px;
    }

    #premiumPageControlArea .control_content_area .control_description p {
        font-size: 22px;
    }

    #premiumPageControlArea .control_content_area .control_description p strong {
        font-size: 29px;
    }

    #premiumPageControlArea .control_content_area .control_pointers span {
        font-size: 23px;
    }

    #premiumPageControlArea .control_content_area .control_cta_container button {
        font-size: 18px;
    }
}

@media (max-width: 1000px) {
    #premiumPageHeroArea .premium_hero_data .hero_data_headings h1 {
        font-size: 32px;
    }

    #premiumPageHeroArea .premium_hero_data .hero_data_subheading p {
        font-size: 17px;
    }

    #premiumPageHeroArea .premium_hero_data .hero_data_cta_button button {
        font-size: 17px;
    }

    #premiumPagePricingArea .premium_pricing_inner {
        width: 70%;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_head h2 {
        font-size: 30px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_plan {
        font-size: 15px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing h2 {
        font-size: 32px;
    }

    #premiumPageFreedomArea .freedom_container_head h2 {
        font-size: 35px;
    }

    #premiumPageFreedomArea .freedom_container_head p {
        font-size: 17px;
    }

    #premiumPageChainArea h2 {
        font-size: 35px;
    }

    #premiumPageFirstStepArea .first_step_container h2 {
        font-size: 40px;
    }

    #premiumPageFirstStepArea .first_step_container p {
        font-size: 18px;
    }

    #premiumPageControlArea .control_content_area .control_description p {
        font-size: 19px;
    }

    #premiumPageControlArea .control_content_area .control_description p strong {
        font-size: 23px;
    }

    #premiumPageControlArea .control_content_area .control_pointers span {
        font-size: 18px;
    }

    #premiumPageControlArea .control_content_area .control_cta_container button {
        font-size: 16px;
    }
}

@media (max-width: 700px) {
    #premiumPageHeader {
        width: 90%;
        padding: 2% 5%;
    }

    #premiumPageHeader img {
        width: 40%;
    }

    #premiumPageHeroArea {
        flex-direction: column;
        width: 90%;
        padding: 0% 5%;
    }

    #premiumPageHeroArea .premium_hero_data {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 2% 0%;
    }

    #premiumPageHeroArea .premium_hero_data .hero_data_headings h1 {
        font-size: 45px;
    }

    #premiumPageHeroArea .premium_hero_data .hero_data_subheading {
        width: 100%;
    }

    #premiumPageHeroArea .premium_hero_data .hero_data_subheading p {
        font-size: 20px;
    }

    #premiumPageHeroArea .premium_hero_data .hero_data_cta_button button {
        padding: 3% 10%;
        width: auto;
        font-size: 17px;
        cursor: pointer;
    }

    #premiumPageHeroArea .premium_hero_bg_img {
        width: 100%;
        text-align: center;
    }

    #premiumPageHeroArea .premium_hero_bg_img img {
        width: 80%;
    }

    #premiumPagePricingArea .premium_pricing_inner {
        width: 100%;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_head img {
        width: 20%;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_head h2 {
        color: #007BFF;
        font-size: 32px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container {
        flex-direction: column;
        align-items: center;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple {
        width: 80%;
        margin-bottom: 20px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing h2 {
        font-size: 35px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing_info p {
        width: 90%;
        font-size: 17px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pointers span {
        font-size: 15px;
        margin-left: 5%;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_button_area button {
        width: 73%;
        font-size: 18px;
        padding: 4%;
    }

    #premiumPageControlArea {
        width: 80%;
        padding: 2% 10%;
        flex-direction: column;
    }

    #premiumPageControlArea .control_content_area {
        width: 100%;
    }

    #premiumPageControlArea .control_content_area .control_description p {
        font-size: 20px;
    }

    #premiumPageControlArea .control_content_area .control_description p strong {
        font-size: 25px;
    }

    #premiumPageControlArea .control_content_area .control_pointers span {
        font-size: 20px;
    }

    #premiumPageControlArea .control_content_area .control_cta_container {
        width: 100%;
    }

    #premiumPageControlArea .control_content_area .control_cta_container button {
        width: auto;
        font-size: 20px;
        padding: 3% 10%;
    }

    #premiumPageControlArea .control_img_area {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 4%;
        margin-bottom: 5%;
    }

    #premiumPageControlArea .control_img_area img {
        width: 80%;
    }

    #premiumPageDownloadsArea .downloads_head_container img {
        width: 35%;
    }
}

@media (max-width: 500px) {
    #premiumPageHeroArea .premium_hero_data .hero_data_headings h1 {
        font-size: 25px;
    }

    #premiumPageHeroArea .premium_hero_data .hero_data_subheading p {
        font-size: 16px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_head h2 {
        font-size: 25px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_plan {
        font-size: 18px;
    }

    #premiumPagePricingArea .premium_pricing_inner .premium_pricing_container .pricing_card_simple .simple_card_pricing h2 {
        font-size: 32px;
    }

    #premiumPageFreedomArea .freedom_container_head h2 {
        font-size: 30px;
    }

    #premiumPageFreedomArea .freedom_container_head p {
        font-size: 15px;
    }

    #premiumPageFreedomArea .freedom_container_pointers img {
        width: 100%;
    }

    #premiumPageChainArea h2 {
        font-size: 25px;
    }

    #premiumPageFirstStepArea .first_step_container h2 {
        font-size: 29px;
    }

    #premiumPageFirstStepArea .first_step_container p {
        font-size: 15px;
    }

    #premiumPageControlArea .control_content_area .control_description p {
        font-size: 18px;
    }

    #premiumPageControlArea .control_content_area .control_description p strong {
        font-size: 23px;
    }

    #premiumPageControlArea .control_content_area .control_pointers span {
        font-size: 15px;
    }
    
    #premiumPageControlArea .control_img_area img {
        width: 90%;
    }

    #premiumPageDownloadsArea .downloads_head_container img {
        width: 45%;
    }

}