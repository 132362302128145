.card_area {
    width: 220px;
    /* height: 300px; */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 0%;
}

.card_area .card_icon_area {
    width: 150px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card_area .card_icon_area .card_icon {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10% 5%;
}

.card_area .card_icon_area .card_level h3 {
    margin-block-start: 0%;
    margin-bottom: 30%;
}

.card_area .card_icon_area .card_icon img {
    width: 90%;
}

.card_title h2 {
    color: #ffffff;
    margin: 20px 0px;
}

.card_description {
    color: #ffffff;
    text-align: center;
    width: 80%;
}

.card_progress_area {
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    justify-self: flex-end;
}

.card_progress_area .card_progress_bar {
    width: 80%;
    height: 10px;
    background-color: #ffffff;
    margin-right: 10px;
    border-radius: 10px;
}

.card_progress_area .card_progress_bar .card_progress_inner {
    height: 100%;
    background: rgb(249,83,198);
    background: linear-gradient(180deg, rgba(249,83,198,1) 0%, rgba(185,29,115,1) 100%);
    border-radius: 10px;
}

.card_progress_num {
    color: #ffffff;
    font-size: 1.2em;
}

@media (max-width: 1200px) {
    .card_area {
        width: 180px;
    }

    .card_area .card_icon_area {
        width: 130px;
    }

    .card_area .card_icon_area .card_icon {
        width: 100px;
    }
}

@media (max-width: 900px) {
    .card_area {
        width: 130px;
    }

    .card_area .card_icon_area {
        width: 100px;
    }

    .card_area .card_icon_area .card_icon {
        width: 80px;
    }

    .card_title h2 {
        font-size: 18px;
    }

    .card_description {
        font-size: 12px;
    }

    .card_progress_num {
        color: #ffffff;
        font-size: 13px;
    }
}

@media (max-width: 600px) {
    .card_area {
        width: 80%;
        padding: 2% 3%;
        margin-bottom: 15px;
    }

    .card_area .card_icon_area {
        width: 90%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .card_area .card_icon_area .card_icon {
        width: 15%;
        padding: 3% 1%;
    }

    .card_area .card_icon_area .card_level h3 {
        margin-block-end: 0%;
    }
}