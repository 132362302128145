#ticket_form_container {
    width: 100%;
    height: 100vh;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#ticket_form_container .ticket_form_header {
    height: 14vh;
    width: 100%;
}

#ticket_form_container .ticket_form_header .empty_liner {
    width: 100%;
    height: 2.8vh;
    background-color: #102129;
}

#ticket_form_container .ticket_form_header .ticket_form_heading {
    width: 95%;
    height: 11.2vh;
    /* border-bottom: 1px solid #9D9D9D; */
    padding-left: 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    color: #1B343C;
}

#ticket_form_container .ticket_form_body {
    width: 90%;
    height: 86vh;
    background-color: #FBFBFB;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5%;
}

#ticket_form_container .ticket_form_body .form_content_area h2 {
    font-size: 18px;
    color: #264652;
}

#ticket_form_container .ticket_form_body .form_content_area .form_content_options {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#ticket_form_container .ticket_form_body .form_content_area .form_content_options span {
    width: 92%;
    border: 1px solid #A1A1A1;
    padding: 4%;
    font-size: 15px;
    color: #2B5162;
    margin-top: 4%;
    border-radius: 10px;
    cursor: pointer;
}

#ticket_form_container .ticket_form_body .form_content_area .form_content_options span:hover {
    border: 1px solid #2C5263;
}

#ticket_form_container .ticket_form_body .form_content_area .form_content_options .selectedApp {
    border: 1px solid #2C5263;
    background-color: #2B5162;
    color: #ffffff;
}

#ticket_form_container .ticket_form_body .form_content_area .form_content_options .correct {
    border: 1px solid #2C5263;
    background-color: #2B5162;
    color: #ffffff;
}

#ticket_form_container .ticket_form_body .form_content_area .platforms_form_tags_area .platform_form_tags_inner {
    width: 92%;
    border: 1px solid #A1A1A1;
    padding: 4%;
    font-size: 15px;
    color: #2B5162;
    margin-top: 4%;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

#ticket_form_container .ticket_form_body .form_content_area .platforms_form_tags_popup_area {
    margin-top: 3%;
}

#ticket_form_container .ticket_form_body .form_content_area .platforms_form_tags_popup_area .platform_form_tag_popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 20px;
    border: 1px solid #A1A1A1;
    overflow: hidden;
}

#ticket_form_container .ticket_form_body .form_content_area .platforms_form_tags_popup_area .platform_form_tag_popup button {
    width: 100%;
    padding: 4%;
    background: transparent;
    border: none;
    border-bottom: 1px solid #A1A1A1;
    font-size: 15px;
}

#ticket_form_container .ticket_form_body .form_content_area .platforms_form_tags_popup_area .platform_form_tag_popup button:hover {
    background-color: #A1A1A1;
}

#ticket_form_container .ticket_form_body .form_content_area .form_content_extra_info {
    font-size: 13px;
    color: #A1A1A1;
}

#ticket_form_container .ticket_form_body .form_content_area .ticket_documents_area {
    display: flex;
    width: 92%;
    padding: 4%;
    border: 1px solid #A1A1A1;
    border-radius: 10px;
    margin-top: 6%;
    margin-bottom: 6%;
    align-items: center;
}

#ticket_form_container .ticket_form_body .form_content_area .ticket_documents_area img {
    width: 10%;
    height: 100%;
    margin-right: 10%;
}

#ticket_form_container .ticket_form_body .form_content_area .ticket_documents_area p {
    font-size: 15px;
    color: #2B5162;
}

#ticket_form_container .ticket_form_body .form_content_area .remove_all_files_area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 5%;
}

#ticket_form_container .ticket_form_body .form_content_area .remove_all_files_area button {
    background-color: #2B5162;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    align-items: center;
    padding: 3% 5%;
    border: none;
    border-radius: 5px;
}

#ticket_form_container .ticket_form_body .form_content_area .remove_all_files_area button span {
    font-size: 16px;
    margin-left: 10px;
}

#ticket_form_container .ticket_form_body .form_content_area .list_of_added_files .single_added_file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 92%;
    padding: 4%;
    border: 1px solid #A1A1A1;
    border-radius: 10px;
    margin-top: 2%;
}

#ticket_form_container .ticket_form_body .form_content_area .list_of_added_files .single_added_file .file_status_icon {
    width: 7%;
}

#ticket_form_container .ticket_form_body .form_content_area .list_of_added_files .single_added_file .added_files_progress_area {
    width: 70%;
    text-align: left;
}

#ticket_form_container .ticket_form_body .form_content_area .list_of_added_files .single_added_file .added_files_progress_area span {
    font-size: 15px;
    color: #2B5162;
}

#ticket_form_container .ticket_form_body .form_content_area .list_of_added_files .single_added_file .added_files_progress_area .added_files_progress {
    width: 100%;
    height: 5px;
    border-radius: 20px;
    transition: all ease 1s;
    background-color: #D1E1E8;
}

#ticket_form_container .ticket_form_body .form_content_area .list_of_added_files .single_added_file .added_files_progress_area .added_files_progress .added_files_progress_inner {
    background-color: #E64919;
    height: 5px;
    border-radius: 20px;
}

#ticket_form_container .ticket_form_body .form_content_area .list_of_added_files .single_added_file .added_file_cta {
    width: 10%;
}

#ticket_form_container .ticket_form_body .form_content_area .list_of_added_files .single_added_file .added_file_cta button{
    width: 100%;
    background: transparent;
    border: none;
}

#ticket_form_container .ticket_form_body .form_content_area .list_of_added_files .single_added_file .added_file_cta button svg {
    font-size: 18px;
}

#ticket_form_container .ticket_form_body .form_content_area .platforms_form_email_area {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
}

#ticket_form_container .ticket_form_body .form_content_area .platforms_form_email_area input {
    width: 62%;
    border: 1px solid #A1A1A1;
    padding: 4%;
    font-size: 15px;
    color: #2B5162;
    border-radius: 10px;
    cursor: pointer;
}

#ticket_form_container .ticket_form_body .form_content_area .platforms_form_email_area button {
    width: 28%;
    background-color: #2B5162;
    border: 1px solid #2C5263;
    color: #ffffff;
    border-radius: 10px;
}

#ticket_form_container .ticket_form_body .form_content_area .platforms_form_email_area button:disabled {
    background-color: #b4b4b4;
    color: #565656;
    border: 1px solid #b4b4b4;
}

#ticket_form_container .ticket_form_body .form_content_area .platforms_form_email_check_area {
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4%;
}

#ticket_form_container .ticket_form_body .form_content_area .platforms_form_email_check_area input {
    margin-right: 10px;
    width: 17px;
    height: 17px;
}

#ticket_form_container .ticket_form_body .form_submit_area {
    width: 100%;
    display: flex;
    justify-content: center;
}

#ticket_form_container .ticket_form_body .form_submit_area button {
    width: 100%;
    background-color: #E64919;
    padding: 4% 0%;
    color: #ffffff;
    border: 1px solid #E64919;
    font-size: 18px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
}

#ticket_form_container .ticket_form_body .form_submit_area button:disabled {
    background-color: #595959;
    border: 1px solid #595959;
}

@media (min-width: 800px) {
    #ticket_form_container .ticket_form_body {
        width: 60%;
    }
}

@media (min-width: 1200px) {
    #ticket_form_container .ticket_form_body {
        width: 40%;
    }
}

.alert_container {
    width: 100vw;
    height: 100vh;
    background-color: #30303077;
    position: absolute;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert_container .alert_card {
    background-color: #f4f4f4;
    width: 80%;
    padding: 5%;
    border-radius: 10px;
}

.alert_container .alert_card .close_area_alert {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
}

.alert_container .alert_card .alert_content_area {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 30px;
    margin-top: 3%;
}

.alert_container .alert_card .alert_content_area svg {
    width: 15%;
    margin-right: 3%;
}

.alert_container .alert_card .alert_content_area span {
    font-size: 20px;
    color: #db4c24;
}
