.submit_main {
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit_thank_you_area {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.submit_thank_you_area img {
    width: 60%;
}

.submit_thank_you_area h1 {
    font-size: 25px;
    text-align: center;
}

@media (max-width: 600px) {
    .submit_main .submit_thank_you_area {
        width: 60%;
    }
}