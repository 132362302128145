#ticket_feedback_review_page {
    background-color: #e1e1e1ff;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ticket_feedback_review_page .ticket_feedback_container {
    background-color: #ffffff;
    padding: 5%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#ticket_feedback_review_page .ticket_feedback_container img {
    width: 250px;
}

#ticket_feedback_review_page .ticket_feedback_container .ticket_feedback_ask {
    width: 100%;
}

#ticket_feedback_review_page .ticket_feedback_container .ticket_feedback_ask h3 {
    margin-top: 4%;
    margin-bottom: 2%;
}

#ticket_feedback_review_page .ticket_feedback_container .ticket_feedback_ask .ticket_feedback_options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#ticket_feedback_review_page .ticket_feedback_container .ticket_feedback_ask .ticket_feedback_options span {
    width: 60%;
    font-size: 16px;
    background-color: #b1f2cc;
    margin-bottom: 2%;
    padding: 2%;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

#ticket_feedback_review_page .ticket_feedback_container .ticket_feedback_ask .ticket_feedback_options span:hover {
    color: #ffffff;
    background-color: #001f0d;
}