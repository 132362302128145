.overTopAll {
    height: 100%;
    width: 100%;
  }
  
  .homeOuter23 {
    background-color: #e1f5fe;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 20px 15px 0;
    height: 100%;
    margin: auto;
    flex-direction: column;
    align-items: center;
  }
  
  .contentHome1 {
    margin: 0;
    padding: 20px;
    box-sizing: inherit;
    width: 90%;
    -webkit-box-direction: normal;
    height: 100%;
  }
  
  
  .homeText2 {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
    /* align-items: center; */
  }
  
  .centText {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 900;
    color: black;
  }