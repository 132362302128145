.footerDiv {
    position: relative;
    background: linear-gradient(to bottom, transparent 55%, rgba(0, 0, 0, 0.2) 55%);
    padding: 80px 0px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.footerDiv .footerMain {
    position: relative;
    z-index: 1;
    background: #0D2932;
    width: 70%;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    padding: 3% 2%;
    border-radius: 10px;
}

.footerMain .footerSection {
    /* width: 30%; */
    padding: 0% 1% 0% 3%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footerMain .blogSection {
    border-left: 2px;
    width: 40%;
    border-left: 1px solid #676767;
    border-right: 1px solid #676767;
}

.footerSection .footerHeading {
    color: #E64A19;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: left;
    margin-bottom: 10px;
}

.footerSection .blogTitle {
    text-align: left;
    font-size: .9rem;
    display: flex;
    flex-direction: column;
}

.footerSection .blogTitle span {
    margin-bottom: 5px;
}

.footerSection .blogTitle a {
    color: #FFFFFF;
    font-weight: 500;
    text-decoration: none;
}

.footerSection .blogTitle a:hover {
    color: #E64A19;
    font-weight: 700;
}

.footerSection .blockerxLogo {
    margin-bottom: 10px;
}

.footerSection .blockerxLogo img {
    width: 180px;
}

.footerSection .promotionLinks {
    display: flex;
    width: 100%;
}

.footerSection .promotionLinks .promoButton {
    background-color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.footerMain .logoSection {
    width: 35%;
    border-right:  1px solid #676767;;
}

.footerMain .productSection {
    width: 60%;
    margin-left: 5%;
}

.footerSection .promotionLinks .promoButton img {
    width: 75px;
}

.footerSection .promotionLinks .community img {
    width: 90px;
}

.footerSection .actionButtons {
    display: flex;
    flex-direction: column;
}

.footerSection .actionButtons button {
    display: none;
    flex-direction: column;
    margin-bottom: 15px;
    color: #E64A19;
    background-color: #0D2932;
    padding: 6%;
    width: 180px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    border: 1px solid #E64A19;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
}

.footerSection .actionButtons button:hover {
    color: #FFF;
    background-color: #E64A19;
}

.footerSection .productsList {
    align-items: flex-start;
    text-align: left;
}

.footerSection .productsList .productDiv {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    align-items: center;
    font-size: .9rem;
    cursor: pointer;
}

.footerSection .productsList .productDiv:hover {
    color: #E64A19;
    font-weight: 600;
}

.footerSection .productDiv .productIcon {
    display: flex;
    width: 40px;
    height: 40px;
    background-color: #000;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    flex: 0 0 40px;
}

.productDiv .productIcon img {
    width: 20px;
    height: auto;
}

@media (max-width: 1100px) {
    .footerDiv .footerMain {
        width: 80%;
    }
}

@media (max-width: 900px) {
    .footerDiv .footerMain {
        width: 85%;
    }
}

@media (max-width: 800px) {
    .footerDiv .footerMain {
        width: 94%;
    }
}

@media (max-width: 700px) {
    .footerDiv .footerMain {
        width: 100%;
        border-radius: 0px;
        flex-direction:column-reverse;
        padding: 30px;
    }

    .footerDiv {
        padding: 0px 0px;
        margin-top: 50px;
    }

    .footerMain .blogSection {
        width: 100%;
        border-left: none;
        border-right: none;
    }

    .footerMain .footerSection {
        width: 100%;
        margin-bottom: 30px;
    }

    .footerMain .logoSection {
        width: 100%;
        border-right:  none;
    }

    .footerMain .productSection {
        width: 90%;
        margin-left: 0%;
    }
}

@media (max-width: 480px) {
    .footerDiv {
        margin-top: 0px;
    }

    .footerDiv .footerMain {
        padding-bottom: 90px;
    }

    .footerMain .blogSection {
        display: none;
    }

    .footerSection .actionButtons button {
        display: flex;
    }

    .footerSection .promotionLinks .promoButton {
        width: 45%;
    }
}