@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;800&display=swap');

#nav_bar_blockerx {
    width: 80%;
    padding: 1% 10%;
    background-color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: fixed; */
    /* z-index: 20; */
}

#nav_bar_blockerx_mobile {
    background-color: #333;
    display: none;
    /* position: fixed; */
    /* z-index: 20; */
    width: 100%;
}

.show {
    transform: translateY(0%);
}

.hide {
    transform: translateY(-100%);
}

#nav_bar_blockerx .home_brand_area {
    width: 15%;
}

#nav_bar_blockerx .home_butn_area {
    width: 50%;
    text-align: end;
}

#nav_bar_blockerx .home_butn_area button {
    background: transparent;
    border: 1px solid #e64a19;
    color: #e64a19;
    padding: 2% 3%;
    width: 160px;
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
}

#nav_bar_blockerx .home_butn_area #signupForNewUser {
    margin-right: 3%;
}

#nav_bar_blockerx .home_butn_area button:hover {
    background-color: #e64a19;
    color: #ffffff;
}

#nav_bar_blockerx .home_brand_area img {
    width: 100%;
    cursor: pointer;
}

#sale_header {
    width: 70%;
    background-color: #FFDFA1;
    display: flex;
    padding: 1% 15% 1% 15%;
    position: sticky;
    top: 0;
    z-index: 20;
    justify-content: space-between;
    align-items: center;
}

#sale_header .details_area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#sale_header .details_area h2 {
    font-size: 28px;
    font-weight: 600;
}

#sale_header .details_area h3 {
    font-size: 18px;
    font-weight: 300;
}

#sale_header .details_area h3 strong {
    color: #e64a19;
}

#sale_header .deal_area h1 {
    font-size: 45px;
    font-family: 'Oswald', sans-serif;
    color: #e64a19;
    letter-spacing: 0px;
}

#cohort_program_banner {
    width: 70%;
    padding: 6% 15% 3% 15%;
    background-color: #0E3C2C;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    font-family: 'Rubik', sans-serif;
}

#cohort_program_banner .cohort_content_area {
    /* background: #333; */
    width: 60%;
}

#cohort_program_banner .cohort_content_area .cohort_content_heading {
    font-size: 45px;
    letter-spacing: -0.41px;
    text-align: left;
}

#cohort_program_banner .cohort_content_area .cohort_content_subheading {
    font-size: 20px;
    letter-spacing: -0.41px;
    text-align: left;
    width: 90%;
    color: #C2CECA;
    margin-top: 5px;
}

#cohort_program_banner .cohort_content_area .cohort_content_keys {
    background: #1A4637;
    border-radius: 10px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

#cohort_program_banner .cohort_content_area .cohort_content_keys .keys_point {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3% 4%;
}

#cohort_program_banner .cohort_content_area .cohort_content_keys .keys_point h3 {
    font-size: 17px;
    font-weight: 400;
}

#cohort_program_banner .cohort_content_area .cohort_content_keys .keys_point p {
    font-size: 12px;
    font-weight: 200;
    color: #C2CECA;
}

#cohort_program_banner .cohort_content_area .cohort_content_keys .add_border {
    border-right: #183C30 1.5px solid;
}

#cohort_program_banner .cohort_content_area .cohort_master_info {
    display: flex;
    width: 90%;
    border-radius: 10px;
    margin-top: 20px;
}

#cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_img_area {
    width: 40%;
    height: 250px;
    background: #1A4637;
    display: flex;
    justify-content: center;
    border-radius: 10px 0px 0px 10px;
}

#cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_extras {
    background-color: #ECF3F1;
    width: 60%;
    text-align: left;
    padding: 3%;
    border-radius: 0px 10px 10px 0px;
}

#cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_extras h2 {
    color: #000000;
    font-weight: 400;
    font-size: 22px;
}

#cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_extras p {
    margin-top: 7px;
    color: #666666;
    font-weight: 400;
    font-size: 16px;
    text-align: justify;
}

#cohort_program_banner .cohort_pricing_card {
    background: #ffffff;
    border-radius: 0px 0px 10px 10px;
    color: #000000;
    text-align: left;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_info {
    padding: 5%;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_info span {
    color: #666666;
    font-size: 20px;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_info h2 {
    font-size: 30px;
    font-weight: 500;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings {
    background: #ECF3F1;
    padding: 0% 5%;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-direction: column;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #D4DAD8;
    padding-bottom: 30px;
    padding-top: 15px;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing span {
    color: #666666;
    font-size: 17px;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing .cohort_actual_price {
    display: flex;
    margin-top: 8px;
    align-items: center;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing .cohort_actual_price h3 {
    font-size: 32px;
    font-weight: 500;
    margin-right: 20px;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing .cohort_actual_price h4 {
    color: #666666;
    font-size: 22px;
    font-weight: 500;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing_offer {
    background-color: #0A7D41;
    color: #ffffff;
    padding: 3%;
    width: 50px;
    height: 75px;
    text-align: center;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing_offer .cohort_ribbon_cut {
    width: 50px;
    height: 50px;
    background: #ECF3F1;
    position: absolute;
    transform: rotate(45deg);
    translate: 0px 15px;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing_offer strong {
    display: block;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing_offer span {
    font-size: 15px;
    text-align: center;
    color: #C2CECA;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_register_button {
    padding: 7% 0%;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_register_button button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;
    padding: 5% 7%;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    font-size: 20px;
}

#cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_register_button button:hover {
    cursor: pointer;
}

.mobile_details_area .details_countdown h3 strong {
    color: #e64a19;
}


#offer_ending_banner {
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

#offer_ending_banner .offer_ending {
    color: #000;
    background-color: #FFDFA1;
    font-size: .7rem;
    font-weight: 600;
    padding: 5% 10%;
    cursor: pointer;
}

@media (max-width: 1810px) {
    #cohort_program_banner {
        width: 80%;
        padding: 6% 10% 3% 10%;
    }

    #cohort_program_banner .cohort_content_area {
        width: 70%;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_keys .keys_point {
        padding: 3% 5%;
    }
}

@media (max-width: 1810px) {
    #cohort_program_banner .cohort_content_area .cohort_content_heading {
        font-size: 35px;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_subheading {
        font-size: 18px;
        width: 80%;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_extras h2 {
        font-size: 18px;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_extras p {
        font-size: 14px;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing span {
        color: #666666;
        font-size: 15px;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing .cohort_actual_price h3 {
        font-size: 25px;
        margin-right: 10px;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing .cohort_actual_price h4 {
        font-size: 18px;
    }
}

@media (max-width: 1100px) {

    #sale_header {
        width: 80%;
        padding: 1% 10% 1% 10%;
    }
    
    #sale_header .details_area{
        align-items: center;
    }
}

@media (max-width: 1000px) {
    #cohort_program_banner {
        flex-direction: column;
        align-items: center;
        width: 80%;
        padding: 6% 10% 5% 10%;
    }

    #cohort_program_banner .cohort_content_area {
        width: 100%;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_heading {
        font-size: 25px;
        text-align: center;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_subheading {
        font-size: 20px;
        text-align: center;
        width: 100%;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_keys {
        width: 100%;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info {
        width: 100%;
        margin-bottom: 20px
    }

    #cohort_program_banner .cohort_pricing_card {
        border-radius: 10px;
        width: 100%;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_info {
        padding: 2% 5%;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing_offer .cohort_ribbon_cut {
        width: 70px;
        height: 70px;
        background: #ECF3F1;
        position: absolute;
        translate: -10px 20px;
    }
}

@media (max-width: 850px) {
    #nav_bar_blockerx {
        width: 90%;
        padding: 1% 5%;
    }
}

@media (max-width: 800px) {
    #nav_bar_blockerx .home_brand_area {
        width: 25%;
    }

    #sale_header {
        width: 94%;
        padding: 1% 3% 1% 3%;
    }

    #cohort_program_banner {
        padding: 10% 10% 5% 10%;
    }
}

@media (max-width: 700px) {
    #nav_bar_blockerx {
        flex-direction: column;
        align-items: center;
    }

    #nav_bar_blockerx .home_brand_area {
        width: 35%;
    }

    #nav_bar_blockerx .home_butn_area {
        width: 100%;
        text-align: center;
    }

    #sale_header {
        width: 84%;
        padding: 2% 8% 2% 8%;
        flex-direction: column;
    }

    #cohort_program_banner {
        padding: 20% 10% 5% 10%;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_extras h2 {
        font-size: 15px;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_extras p {
        font-size: 13px;
    }
}

@media (max-width: 480px) {
    #sale_header {
        width: 94%;
        padding: 3% 3% 3% 3%;
    }

    #nav_bar_blockerx {
        display: none;
    }

    #nav_bar_blockerx_mobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #nav_bar_blockerx_mobile .home_brand_area {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #nav_bar_blockerx_mobile .home_brand_area img {
        width: 180px;
        padding: 10%;
    }

    #nav_bar_blockerx .home_brand_area {
        width: 60%;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    #nav_bar_blockerx .home_butn_area #signupForNewUser {
        margin-right: 0%;
    }

    #nav_bar_blockerx .home_butn_area button {
        width: 220px;
        font-size: 1.2rem;
        margin-bottom: 10px;
    }

    #cohort_program_banner {
        padding: 45% 10% 5% 10%;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_heading {
        font-size: 20px;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_subheading {
        font-size: 16px;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_keys {
        flex-direction: column;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_keys .keys_point {
        padding: 5% 4%;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_keys .add_border {
        border-right: #183C30 0px solid;
        border-bottom: #183C30 1px solid;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info {
        flex-direction: column;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_img_area {
        width: 100%;
        height: auto;
        border-radius: 10px 10px 0px 0px;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_img_area img {
        width: 100%;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_extras {
        width: 94%;
        border-radius: 0px 0px 10px 10px;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_extras h2 {
        font-size: 18px;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_extras p {
        font-size: 13px;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing_offer .cohort_ribbon_cut {
        width: 50px;
        height: 50px;
        translate: 0px 15px;
    }

    #offer_ending_banner {
        display: flex;
    }
}

@media (max-width: 400px) {
    #sale_header {
        padding: 3% 3% 3% 3%;
    }

    #cohort_program_banner {
        padding: 50% 10% 5% 10%;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_heading {
        font-size: 15px;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_subheading {
        font-size: 13px;
    }

    #cohort_program_banner .cohort_content_area .cohort_content_keys .keys_point h3 {
        font-size: 15px;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_extras h2 {
        font-size: 15px;
    }

    #cohort_program_banner .cohort_content_area .cohort_master_info .cohort_master_extras p {
        font-size: 12px;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_info span {
        font-size: 15px;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_info h2 {
        font-size: 20px;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area {
        padding-bottom: 18px;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing_offer {
        width: 40px;
        height: 60px;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing_offer .cohort_ribbon_cut {
        width: 40px;
        height: 40px;
        translate: 0px 9px;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing_offer strong {
        font-size: 18px;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_pricing_area .cohort_pricing_offer span {
        font-size: 12px;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_register_button {
        padding: 5% 0%;
    }

    #cohort_program_banner .cohort_pricing_card .cohort_session_pricings .cohort_register_button button {
        border-radius: 10px;
        font-size: 15px;
    }
}