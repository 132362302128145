@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800;900&display=swap');

/* *,
*:after,
*:before {
    box-sizing: border-box;
} */

.ratings-page {
    background-color: white;
    height: 100vh;
}

.card-list {
    text-align: center;
    font-family: "Lexend", sans-serif;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #393232;
    width: 100%;
    min-width: 100%;
}

.card {
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 20px 50px 0 rgba(0, 0, 0, .1);
    border-radius: 15px;
    overflow: hidden;
    padding: 1.25rem;
    position: relative;
    transition: 0.15s ease-in;
    margin: 20px;
}

.card-header {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-header .org-name {
    color: #E64A22;
}

.card-header h1 {
    margin-block-start: 0%;
    margin-block-end: 0%;
    font-size: 2rem;
}

.card-subheader {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.card-subheader span {
    font-weight: 300;
    font-size: 1.5rem;
    padding-bottom: 2rem;
}

.card-body {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-body .icon-button {
    margin: 5px;
}

.icon-button {
    border: 0;
    background-color: #fff;
    border-radius: 50%;
    width: 3.0rem;
    height: 3.0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: 1.5rem;
    /* transition: 0.25s ease; */
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 3px 8px 0 rgba(0, 0, 0, .15);
    /* z-index: 1; */
    cursor: pointer;
    color: #000000;
}

.card-body .on {
    background-color: #E64A22;
    /* color: #ffffff; */
}

.card-body .on {
    color: #ffffff;
}

.card-footer {
    margin-top: 1.25rem;
    border-top: 1px solid #ddd;
    padding-top: 1.25rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
