.daily_tasks_container {
    width: 100%;
    padding-top: 3%;
    border-top: 1px solid #D8D8D8;
}

.daily_tasks_container .daily_heading {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.daily_tasks_container .daily_heading img {
    width: 30px;
    margin-right: 10px;
}

.daily_tasks_container .daily_heading h1 {
    font-size: 18px;
}

.daily_tasks_container .daily_header_area {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}
 
.daily_tasks_container .daily_header_area .daily_header_inner {
    width: 80%;
    background: linear-gradient(90deg, #439CFB 0%, #F187FB 100%);
    color: #ffffff;
    padding: 3%;
    border-radius: 10px;
}
 
.daily_tasks_container .daily_tasks_area {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
}
 
.daily_tasks_container .daily_tasks_area .daily_tasks_list {
    width: 70%;
    display: flex;
    flex-direction: column;
}
 
.daily_tasks_container .daily_tasks_area .daily_tasks_list .daily_task_element {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
 
.daily_tasks_container .daily_tasks_area .daily_tasks_list .daily_task_element button {
    width: 130px;
    background: transparent;
    border: none;
    cursor: pointer;
}
 
.daily_tasks_container .daily_tasks_area .daily_tasks_list .daily_task_element button img {
    width: 100%;
}
 
.task_info {
    position: absolute;
    display: inline-block;
    padding: 15px 20px;
    width: 120px;
    font-size: 15px;
    font-weight: bold;
    color: white;
    background-color: #000000;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    z-index: 2;
    transform: translate(-28px, -70px);
    /* animation: bounce2 2s ease infinite; */
}
 
/* Triangle at the bottom */
.task_info::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #000000;
}