.signup_page_container {
    background-color: #ffd3c5;
    height: 100vh;
}
.signup_head {
    display: flex;
    justify-content: space-between;
    padding: 2% 5%;
    align-items: center;
}

.signup_head .signup_head_brand {
    width: 200px;
}

.signup_head .signup_head_brand img{
    width: 100%;
    cursor: pointer;
}

.signup_head .signup_head_btns {
    display: flex;
}

.signup_head .signup_head_btns button {
    width: 200px;
    background-color: #e64a19;
    border-radius: 25px;
    border: none;
    padding: 6% 5%;
    color: #ffffff;
    font-size: 17px;
    cursor: pointer;
}

.signup_head .signup_head_btns button:hover {
    color: #e64a19;
    background-color: #ffffff;
}

.signup_body {
    padding: 0% 5%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.signup_body .signup_body_area {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup_body .signup_body_area .signup_body_text {
    margin-bottom: 3%;
}

.signup_body .signup_body_area .signup_body_text h2{
    font-size: 2rem;
    margin-bottom: 1%;
}

.signup_body .signup_body_area .signup_body_text span{
    font-size: 1.2rem;
}

.signup_body .signup_body_area .signup_body_form {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.signup_body .signup_body_area .signup_body_form input {
    width: 90%;
    padding: 3% 5%;
    font-size: 1rem;
    border-radius: 10px;
    margin-bottom: 2%;
}

.signup_body .signup_body_area .signup_body_form .newsletter_area {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5%;
}

.signup_body .signup_body_area .signup_body_form .newsletter_area input {
    width: 5%;
    margin-bottom: 0%;
    cursor: pointer;
}

.signup_body .signup_body_area .signup_body_form button {
    width: 100%;
    padding: 3% 5%;
    font-size: 1.2rem;
    border-radius: 10px;
    margin-bottom: 2%;
    background-color: #e64a19;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.signup_body .signup_body_area .signup_body_form button:hover {
    background-color: #ffffff;
    color: #e64a19;
}

.signup_body .signup_body_area .partition {
    padding: 3%;
}

.signup_body .signup_body_area .google_area {
    width: 80%;
}

.signup_body .signup_body_area .already_account_login {
    margin-top: 20px;
}

.signup_body .signup_body_area .already_account_login button {
    background: transparent;
    border: none;
    outline: none;
    color: #e64a19;
    font-size: 18px;
    cursor: pointer;
}

.signup_body .signup_body_area .already_account_login button:hover {
    color: #000000;
}

.signup_body .signup_body_area .google_area button {
    width: 100%;
    padding: 3% 5%;
    font-size: 1.2rem;
    border-radius: 10px;
    margin-bottom: 2%;
    background-color: #ffffff;
    color: #000000;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup_body .signup_body_area .google_area button:hover {
    background-color: #e6e6e6;
}

.signup_body .signup_body_area .google_area button img {
    margin-left: 10px;
}

@media (max-width: 1000px) {
    .signup_body .signup_body_area {
        width: 70%;
    }
}

@media (max-width: 700px) {
    .signup_body .signup_body_area {
        width: 80%;
    }
}

@media (max-width: 500px) {
    .signup_body .signup_body_area {
        width: 100%;
    }
    .signup_head {
        flex-direction: column;
        
    }
}