#articles_container {
    background: #0F2027;
    width: 100vw;
    height: 100vh;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#articles_container .articles_inner_container {
    height: 85vh;
    overflow-y: scroll;
    padding: 4%;
    text-align: center;
}

#articles_container .articles_inner_container h1 {
    margin-bottom: 7%;
    margin-top: 2%;
    font-size: 25px;
}

#articles_container .articles_inner_container input {
    margin-bottom: 5%;
    width: 86%;
    background-color: #ffffff2d;
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 4% 7%;
    font-size: 18px;
    color: #ffffff;
}

#articles_container .articles_inner_container .articles_list_area {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#articles_container .articles_inner_container .articles_list_area .article_single_card {
    margin-bottom: 7px;
    background: transparent;
    border: 1px dotted #a2a2a2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2% 4%;
    border-radius: 5px;
    cursor: pointer;
}

#articles_container .articles_inner_container .articles_list_area .article_single_card span {
    width: 80%;
    margin-right: 3px;
    text-align: left;
}

#articles_container .articles_inner_container .articles_list_area .article_single_card svg {
    font-size: 23px;
}

#articles_container .articles_footer {
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d7f3ff;
    color: #0F2027;
}

#articles_container .articles_footer span {
    text-decoration: underline;
    cursor: pointer;
}