#stripe_payment_success_container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#stripe_payment_success_container h1 {
    margin-bottom: 3%;
}

#stripe_payment_success_container .player {
    width: 300px;
}