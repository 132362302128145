#chatbot_page {
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#chatbot_loading {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

.chatbot_header_container {
    width: 90%;
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    display: flex;
    align-items: center;
    padding: 4% 5% 4% 5%;
    color: #ffffff;
    margin: 0%;
}

.chatbot_header_container .chatbot_icon_area {
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-right: 7%;
}

.chatbot_header_container .chatbot_icon_area img {
    width: 90%;
}

.chatbot_current_flow_question {
    width: 96%;
    text-align: center;
    padding: 0% 2%;
}

.chatbot_current_flow_question h4 {
    font-size: 30px;
    padding: 0% 4%;
}

.chatbot_actual_chat_container {
    width: 96%;
    padding: 2% ;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.chatbot_actual_chat_container .single_chat_item {
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    white-space: pre-line;
}

.chatbot_actual_chat_container .user_chat {
    justify-content: flex-end;
}

.chatbot_actual_chat_container .option_chat {
    justify-content: center;
}

.chatbot_actual_chat_container .waiting_gif {
    justify-content: flex-start;
    width: 20%;
}

.chatbot_actual_chat_container .waiting_gif img {
    width: 100%;
}

.chatbot_actual_chat_container .single_chat_item p {
    width: 75%;
    padding: 3%;
    border: 1px solid #ffffff;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
}

.chatbot_actual_chat_container .user_chat p {
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    color: #ffffff;
    overflow-wrap: break-word;
}

.chatbot_actual_chat_container .option_chat p {
    background: #ffffff;
    color: #203A43;
    border: 1px solid #203A43;
}

.chatbot_actual_chat_container .option_chat p .redirect_icon_for_ticket {
    margin-left: 10px;
}

.chatbot_actual_chat_container .option_chat p:hover {
    background: #203A43;
    color: #ffffff;
}

.chatbot_actual_chat_container .bot_chat p {
    background: #e6e6e6;
    color: #203A43;
    border: 1px solid #ffffff;
    overflow-wrap: break-word;
}

.chatbot_footer_container {
    border-top: 1px solid #2C5364;
    margin: 0% 2%;
    padding-bottom: 10px;
    width: 96%;
}

.option_container {
    padding: 3% 0%;
}

.chatbot_footer_container .chat_foot_inp_area {
    width: 96%;
    display: flex;
    justify-content: space-between;
    margin: 2% 2% 0% 2%;
}

.chatbot_footer_container .chat_foot_inp_area input {
    width: 76%;
    background: transparent;
    border: none;
    border-bottom: 2px solid #ffffff;
    font-size: 16px;
    padding: 2% 2%;
    margin-bottom: 5px;
}

.chatbot_footer_container .chat_foot_inp_area input:active, .chatbot_footer_container .chat_foot_inp_area input:focus{
    background: transparent;
    outline: none;
    /* border-bottom: 2px solid #2C5364; */
}

.chatbot_footer_container .chat_foot_inp_area button {
    width: 45px;
    height: 45px;
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    color: #ffffff;
    font-size: 19px;
    padding: 15px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 2%;
}

.chatbot_footer_container .chat_foot_inp_area button:disabled {
    cursor: no-drop;
    background: #727272;
}

.chatbot_footer_container .chat_foot_btn_area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
}

.chatbot_footer_container .chat_foot_btn_area .footer_btns_cta {
    display: flex;
    justify-content: center;
    text-decoration: underline;
    color: #2C5364;
    font-size: 13px;
    width: 100%;
}

.chatbot_footer_container .chat_foot_btn_area .footer_btns_cta span {
    font-size: 16px;
}

.chatbot_footer_container .chat_foot_btn_area .footer_btns_cta button {
    background-color: transparent;
    font-size: 23px;
    border: none;
    margin-right: 20px;
    cursor: pointer;
}

.chatbot_footer_container .chat_foot_btn_area .footer_btns_submit {
    display: flex;
    justify-content: flex-end;
}

.chatbot_footer_container .chat_foot_btn_area .footer_btns_submit button {
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    color: #ffffff;
    font-size: 30px;
    padding: 15px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.chatbot_footer_container .chat_foot_btn_area .footer_btns_submit button:hover {
    color: #0F2027;
    background: #ffffff;
    border: 1px solid #0F2027;
}

.chatbot_footer_container .chat_foot_btn_area .footer_ticket_submit {
    display: flex;
    justify-content: flex-end;
    width: 45%;
}

.chatbot_footer_container .chat_foot_btn_area .footer_ticket_submit button {
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    color: #ffffff;
    font-size: 16px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.chatbot_footer_container .chat_foot_btn_area .footer_ticket_submit button:hover {
    color: #0F2027;
    background: #ffffff;
    border: 1px solid #0F2027;
}

.chatbot_footer_container .chat_foot_btn_area .footer_ticket_submit button svg {
    margin-left: 10px;
}

.chatbot_footer_container .chat_foot_email_redirect {
    width: 100%;
    text-align: center;
    margin: 3% 0%;
}

#chatbot_page .chatbot_body_navigation_area {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

#chatbot_page .chatbot_body_navigation_area button {
    margin: 2% 0%;
    padding: 4%;
    width: 70%;
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    color: #ffffff;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    cursor: pointer;
}

@media (min-width: 1000px) {
    .chatbot_header_container {
        display: flex;
        align-items: center;
        padding: 5% 5% 5% 5%;
    }

    .chatbot_wave {
        display: none;
    }

    .chatbot_current_flow_question {
        padding: 3% 2%;
    }
    
    .chatbot_actual_chat_container .single_chat_item {
        margin-bottom: 5px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    
    .chatbot_actual_chat_container .user_chat {
        justify-content: flex-end;
    }
    
    .chatbot_actual_chat_container .option_chat {
        justify-content: center;
    }
    
    .chatbot_actual_chat_container .single_chat_item p {
        width: 50%;
        padding: 1% 2%;
        border: 1px solid #ffffff;
        border-radius: 10px;
        font-weight: 500;
        font-size: 15px;
    }
    
    .chatbot_actual_chat_container .option_chat p {
        background: #ffffff;
        color: #203A43;
        border: 1px solid #203A43;
    }
    
    .chatbot_actual_chat_container .option_chat p:hover {
        background: #203A43;
        color: #ffffff;
    }
    
    .chatbot_actual_chat_container .bot_chat p {
        background: #e6e6e6;
        color: #203A43;
        border: 1px solid #ffffff;
    }

    .option_container {
        padding: 1% 0%;
    }

    .chatbot_footer_container {
        border-top: 1px solid #2C5364;
        margin: 0% 2%;
        padding-bottom: 10px;
        width: 96%;
    }

    .chatbot_footer_container .chat_foot_inp_area {
        width: 100%;
    }
    
    .chatbot_footer_container .chat_foot_inp_area input {
        width: 96%;
        background: transparent;
        border: none;
        border-bottom: 2px solid #ffffff;
        font-size: 20px;
        padding: 3% 2%;
        margin-bottom: 5px;
    }
    
    .chatbot_footer_container .chat_foot_inp_area input:active, .chatbot_footer_container .chat_foot_inp_area input:focus{
        width: 100%;
        background: transparent;
        outline: none;
        border-bottom: 2px solid #2C5364;
    }
    
    .chatbot_footer_container .chat_foot_btn_area {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .chatbot_footer_container .chat_foot_btn_area .footer_btns_cta {
        display: flex;
        justify-content: flex-start;
        font-size: 17px;
        width: 50%;
    }
    
    .chatbot_footer_container .chat_foot_btn_area .footer_btns_cta button {
        background-color: transparent;
        font-size: 23px;
        border: none;
        margin-right: 20px;
        cursor: pointer;
    }
    
    .chatbot_footer_container .chat_foot_btn_area .footer_btns_submit {
        display: flex;
        justify-content: flex-end;
    }
    
    .chatbot_footer_container .chat_foot_btn_area .footer_btns_submit button {
        background: #0F2027;
        background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
        background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
        color: #ffffff;
        font-size: 30px;
        padding: 15px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    
    .chatbot_footer_container .chat_foot_btn_area .footer_btns_submit button:hover {
        color: #0F2027;
        background: #ffffff;
        border: 1px solid #0F2027;
    }
    
    .chatbot_footer_container .chat_foot_btn_area .footer_ticket_submit {
        display: flex;
        justify-content: flex-end;
    }
    
    .chatbot_footer_container .chat_foot_btn_area .footer_ticket_submit button {
        background: #0F2027;
        background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
        background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
        color: #ffffff;
        font-size: 17px;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    
    .chatbot_footer_container .chat_foot_btn_area .footer_ticket_submit button:hover {
        color: #0F2027;
        background: #ffffff;
        border: 1px solid #0F2027;
    }
    
    .chatbot_footer_container .chat_foot_btn_area .footer_ticket_submit button svg {
        margin-left: 10px;
    }
    
    .chatbot_footer_container .chat_foot_email_redirect {
        width: 100%;
        text-align: center;
        margin: 1% 0%;
    }
}
