.uninstall_main_container {
    width: 100%;
    height: 100vh;
    background-image: url(./uninstallbg.png);
    background-size: cover;
}

.uninstall_main_container .uninstall_main_inner {
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100vh;
}

.uninstall_main_container .uninstall_head {
    padding: 1%;
    display: flex;
    justify-content: center;
}

.uninstall_main_container .uninstall_head img{
    width: 12%;
}

.uninstall_main_container .unintsall_body {
    display: flex;
}

.uninstall_main_container .unintsall_body .uninstall_body_install_again {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 2px solid #e64a19;
    padding-top: 2%;
}

.uninstall_main_container .unintsall_body .uninstall_body_install_again .install_again_img_area {
    width: 70%;
    text-align: center;
}

.uninstall_main_container .unintsall_body .uninstall_body_install_again .install_again_img_area img{
    width: 100%;
    border-radius: 29px;
}

.uninstall_main_container .unintsall_body .uninstall_body_reason_form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uninstall_main_container .unintsall_body .uninstall_body_reason_form .install_again_sentence_area {
    width: 70%;
    text-align: center;
}

.uninstall_main_container .unintsall_body .uninstall_body_reason_form .install_again_button_area {
    width: 60%;
}

.uninstall_main_container .unintsall_body .uninstall_body_reason_form .install_again_button_area button {
    width: 100%;
    padding: 3%;
    background-color: #e64a19;
    font-size: 20px;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    border: 2px solid #e64a19;
}

.uninstall_main_container .unintsall_body .uninstall_body_reason_form .install_again_button_area button:hover {
    background-color: #ffffff;
    color: #e64a19;
}

.uninstall_main_container .unintsall_body .uninstall_body_reason_form .reason_text {
    width: 70%;
    margin: 20% 0% 3% 0%;
}

.uninstall_main_container .unintsall_body .uninstall_body_reason_form .reason_text textarea{
    width: 96%;
    font-size: 20px;
    padding: 2%;
    font-family: 'Poppins', sans-serif;
}

.uninstall_main_container .unintsall_body .uninstall_body_reason_form {
    width: 50%;
    height: 50vh;
}
