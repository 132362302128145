.main_area {
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
  }
  
  .main_area .sub_area {
    background-color: #2b2b2b;
    width: 25%;
    padding: 3%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }
  
  .main_area .sub_area .img_area {
    align-self: center;
    width: 50%;
  }
  
  .main_area .sub_area .img_area img {
    width: 100%;
  }
  
  .main_area .sub_area span {
    font-size: 0.8rem;
    padding: 5%;
    color: #cfcfcf;
    text-align: center;
  }
  
  .main_area .sub_area .btn_area {
    align-self: center;
    display: flex;
    justify-content: center;
  }
  
  .main_area .sub_area .btn_area button {
    padding: 20% 40%;
    background-color: #e64a19;
    border: none;
    color: #ffffff;
    font-size: 1.2em;
    font-weight: 500;
    border-radius: 5px;
  }
  
  .main_area .sub_area .btn_area button:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #e64a19;
  }
  
  @media screen and (max-width: 800px) {
    .main_area .sub_area {
      width: 50%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .main_area .sub_area {
      width: 80%;
    }
  }
  