.community_content {
    padding: 3%;
    margin-bottom: 3%;
}

.community_content h1 {
    text-align: center;
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.community_content h3 {
    font-size: 18px;
    margin-top: 20px;
}

.community_content p {
    font-size: 15px;
}

.community_content ul {
    font-size: 15px;
}