#automation_confirmation {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #0F2027;
    color: #ffffff;
}

#automation_confirmation .automation_success_area {
    margin-top: 20px;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#automation_confirmation .automation_success_area .subheading_automation {
    padding: 0% 5%;
    text-align: center;
}

#automation_confirmation .automation_success_area .all_premium_list {
    height: 80vh;
    overflow-y: auto;
    margin-top: 30px;
    width: 94%;
    display: flex;
    flex-wrap: wrap;
    padding: 3%;
    justify-content: space-evenly;
}

#automation_confirmation .automation_success_area .all_premium_list .single_premium_info_card {
    margin-bottom: 20px;
}

#automation_confirmation span {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
}

.single_premium_info_card .refund_btn_area {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5%;
}

.single_premium_info_card .refund_btn_area p {
    font-size: 13px;
}

.single_premium_info_card .refund_btn_area button {
    width: 100%;
    padding: 2%;
    background: transparent;
    border: 1px solid #0F2027;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
}

.single_premium_info_card .refund_btn_area button:hover {
    background-color: #d4d4d4;
}

.single_premium_info_card .refund_btn_area button:disabled {
    cursor: no-drop;
    background: transparent;
}

.single_premium_info_card .refund_elligible_statement {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 5%;
}

.single_premium_info_card .refund_elligible_statement p {
    font-size: 13px;
}

.single_premium_info_card .refund_elligible_statement button {
    cursor: pointer;
    background: transparent;
    padding: 2%;
    width: 100%;
    border: 1px solid #0F2027;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 700;
}

#automation_google_activation, #automation_stripe_activation {
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#automation_google_activation .automation_footer {
    height: 10vh;
    background-color: #648391;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-decoration: underline;
    cursor: pointer;
}

#automation_google_activation .automation_google_activation_form_area {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#automation_google_activation .automation_google_activation_form_area .automation_google_activation_inner_form {
    background-color: #FBFBFB;
    padding: 3%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%;
}

#automation_google_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
}

#automation_google_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field label {
    display: flex;
    justify-content: space-between;
}

#automation_google_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field label strong {
    margin-bottom: 2%;
    font-weight: 500;
}

#automation_google_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field label span {
    color: #878787;
    font-size: 12px;
}

#automation_google_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field input {
    width: 94%;
    padding: 3%;
    border-radius: 10px;
    border: 1px solid #000000;
}

#automation_google_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field .automation_form_outer_field {
    width: 100%;
    /* padding: 2% 3%; */
    border-radius: 10px;
    /* border: 1px solid #000000; */
}

#automation_google_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field .automation_form_outer_field label {
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 2% 4%;
    cursor: pointer;
}

#automation_google_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_submit {
    width: 100%;
    margin-top: 2%;
}

#automation_google_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_submit button{
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    color: #ffffff;
    border: none;
    width: 100%;
    padding: 3%;
    cursor: pointer;
    border-radius: 10px;
}

#automation_stripe_activation .automation_google_activation_form_area {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#automation_stripe_activation .automation_google_activation_form_area .automation_google_activation_inner_form {
    background-color: #FBFBFB;
    padding: 3%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%;
}

#automation_stripe_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
}

#automation_stripe_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field label {
    display: flex;
    justify-content: space-between;
}

#automation_stripe_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field label strong {
    margin-bottom: 2%;
    font-weight: 500;
}

#automation_stripe_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field label span {
    color: #878787;
    font-size: 12px;
}

#automation_stripe_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field input {
    width: 94%;
    padding: 3%;
    border-radius: 10px;
    border: 1px solid #000000;
}

#automation_stripe_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field .automation_form_outer_field {
    width: 100%;
    /* padding: 2% 3%; */
    border-radius: 10px;
    /* border: 1px solid #000000; */
}

#automation_stripe_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_field .automation_form_outer_field label {
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 2% 4%;
    cursor: pointer;
}

#automation_stripe_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_submit {
    width: 100%;
    margin-top: 2%;
}

#automation_stripe_activation .automation_google_activation_form_area .automation_google_activation_inner_form .automation_form_single_submit button{
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    color: #ffffff;
    border: none;
    width: 100%;
    padding: 3%;
    cursor: pointer;
    border-radius: 10px;
}

#automation_google_activation  .orderIdHelpWindow {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: #00000077;
    display: flex;
    justify-content: center;
    align-items: center;
}

#automation_google_activation  .orderIdHelpWindow .orderIdHelpWindowInner {
    width: 40%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#automation_google_activation  .orderIdHelpWindow .orderIdHelpWindowInner .orderIdHelpClose {
    width: 100%;
    padding: 2% 3%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 35px;
    color: #ffffff;
}

#automation_google_activation  .orderIdHelpWindow .orderIdHelpWindowInner .orderIdHelpClose svg {
    cursor: pointer;
}

#automation_google_activation  .orderIdHelpWindow .orderIdHelpWindowInner .orderIdHelpImage {
    width: 100%;
    height: 100%;
}

#automation_google_activation  .orderIdHelpWindow .orderIdHelpWindowInner .orderIdHelpImage img {
    object-fit: contain;
    height: 100%;
}

#partner_removal_page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dcdcdc;
}

#partner_removal_page .remove_partner_card {
    padding: 2%;
}

#partner_removal_page .information_custom_premium_remove {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

#partner_removal_page .information_custom_premium_remove span {
    font-size: 18px;
}

#partner_removal_page .information_custom_premium_remove .custom_premium_statement {
    margin-bottom: 4%;
}

#partner_removal_page .information_custom_premium {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#partner_removal_page .information_custom_premium .custom_premium_statement {
    font-size: 17px;
    text-align: center;
    margin-bottom: 5%;
}

#partner_removal_page .information_custom_premium h3 {
    font-size: 19px;
    text-align: center;
}

#partner_removal_page .alert_confirmation_popup {
    width: 100%;
    height: 100vh;
    background-color: #0000005d;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

#partner_removal_page .alert_confirmation_popup .alert_inner_popup {
    background-color: #ffffff;
    padding: 3%;
    margin: 0% 3%;
    border-radius: 5px;
}

#direct_automation_issue .alert_confirmation_popup .alert_inner_popup .inner_popup_text {
    margin-bottom: 3%;
}

#partner_removal_page .alert_confirmation_popup .alert_inner_popup .removal_btn_area {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

#partner_removal_page .alert_confirmation_popup .alert_inner_popup .removal_btn_area button {
    width: 48%;
    padding: 4% 2%;
    margin-top: 2%;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    background-color: #e0e0e0;
    color: #000000;
}

#direct_automation_issue .alert_confirmation_popup {
    width: 100%;
    height: 100vh;
    background-color: #0000005d;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

#direct_automation_issue .alert_confirmation_popup .alert_inner_popup {
    background-color: #ffffff;
    padding: 3%;
    margin: 0% 3%;
    border-radius: 5px;
}

#direct_automation_issue .alert_confirmation_popup .alert_inner_popup .removal_btn_area {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

#direct_automation_issue .alert_confirmation_popup .alert_inner_popup .removal_btn_area button {
    width: 48%;
    padding: 2%;
    margin-top: 2%;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

#partner_removal_page .custom_cta_btn {
    width: 100%;
    padding: 4% 0%;
    background-color: #575757;
    border: none;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
}

#restore_streak_page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dcdcdc;
}

#restore_streak_page .restore_streak_card {
    padding: 2%;
}

#restore_streak_page .custom_cta_btn {
    width: 100%;
    padding: 2%;
}

#restore_streak_page .restore_streak_card .information_custom_premium .restore_clear_instruction {
    display: block;
    margin-bottom: 6%;
}

#restore_streak_page .restore_streak_card .information_custom_premium .streak_icon_in_between {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5%;
}

@media (max-width: 1000px) {
    #automation_google_activation .automation_google_activation_form_area .automation_google_activation_inner_form {
        width: 90%;
    }

    #automation_google_activation .automation_google_activation_form_area h1 {
        padding: 0% 3%;
    }

    #automation_stripe_activation .automation_google_activation_form_area .automation_google_activation_inner_form {
        width: 90%;
    }

    #automation_stripe_activation .automation_google_activation_form_area h1 {
        padding: 0% 3%;
    }
    
    #automation_google_activation  .orderIdHelpWindow .orderIdHelpWindowInner {
        width: 80%;
        height: 80%;
    }
    
    #automation_google_activation  .orderIdHelpWindow .orderIdHelpWindowInner .orderIdHelpClose {
        padding: 2% 1%;
    }
    
    #automation_google_activation  .orderIdHelpWindow .orderIdHelpWindowInner .orderIdHelpImage {
        width: 100%;
        height: auto;
    }
    
    #automation_google_activation  .orderIdHelpWindow .orderIdHelpWindowInner .orderIdHelpImage img {
        object-fit: contain;
        width: 100%;
    }
}
