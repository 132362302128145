#customize_login_area {
    overflow: hidden;
    width: 94%;
    min-height: 100vh;
    background-color: #f2e3ff;
    padding: 0% 3%;
}

.custom_login_head {
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
}

.custom_login_head img {
    width: 20%;
}

.custom_login_head .customize_head_forgot {
    background-color: #6019a2;
    color: #ffffff;
    border: none;
    padding: 0% 4%;
    border-radius: 50px;
    font-size: 15px;
    cursor: pointer;
}

.custom_login_body {
    width: 100%;
    display: flex;
    justify-content: center;
}

.custom_login_body .custom_login_body_area {
    width: 50%;
    text-align: center;
}

.custom_login_body .custom_login_body_area .custom_login_body_text {
    margin: 2% 0%;
}

.custom_login_body .custom_login_body_area .custom_login_body_text h2 {
    color: #6019a2;
    font-size: 40px;
}

.custom_login_body .custom_login_body_area .signup_body_form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.custom_login_body .custom_login_body_area .signup_body_form input {
    width: 60%;
    padding: 3%;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #6019a2;
    font-size: 16px;
    color: #6019a2;
    margin-bottom: 10px;
}

.custom_login_body .custom_login_body_area .signup_body_form button {
    width: 66%;
    padding: 2%;
    background-color: #6019a2;
    border-radius: 10px;
    border: 1px solid #6019a2;
    color: #ffffff;
    font-size: 16px;
    margin-top: 4%;
    cursor: pointer;
}

.custom_login_body .custom_login_body_area .signup_body_form button:hover {
    background-color: #ffffff;
    color: #6019a2;
}

.custom_login_body .custom_login_body_area .partition {
    margin: 5% 0%;
}

.custom_login_body .custom_login_body_area .google_area {
    display: flex;
    justify-content: center;
}

.custom_login_body .custom_login_body_area .google_area button {
    width: 66%;
    background-color: #ffffff;
    padding: 2% 0%;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.custom_login_body .custom_login_body_area .google_area button:hover {
    background-color: #dedede;
}

.custom_login_body .custom_login_body_area .google_area button img {
    margin-left: 2%;
}

#customize_container {
    width: 100%;
    /* padding: 2% 0%; */
    min-height: 100vh;
    background-color: #f2e3ff;
    text-align: center;
}

#customize_container h1 {
    font-size: 35px;
    padding-top: 1%;
}

#customize_container h2 {
    font-size: 25px;
    margin-bottom: 5%;
}

#customize_container h2 strong {
    color: #6019a2;
}

#customize_container .information_custom_premium {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    height: 300px;
}

.extra_info_area {
    width: 98%;
    text-align: end;
    font-size: 20px;
    cursor: pointer;
    margin-top: 2%;
}

#customize_container .information_custom_premium h3 {
    margin-top: 3%;
    margin-bottom: 1%;
}

#customize_container .information_custom_premium span {
    margin-bottom: 5px;
    text-align: left;
}

#customize_container .custom_cta_btn {
    width: 100%;
    height: 50px;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #f2e3ff;
    color: #6019a2;
    cursor: pointer;
    font-weight: 600;
}

#customize_container .custom_cta_btn:hover {
    color: #ffffff;
    background-color: #6019a2;
}

#customize_container .custom_cta_btn:disabled {
    color: #474747;
    background-color: #cecece;
    cursor: no-drop;
}

#customize_container .custom_cta_btn_apple {
    border:  1px #474747 dotted;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    font-size: 15px;
    outline: none;
    padding: 1%;
    color: #474747;
    background-color: #ffffff;
    cursor: pointer;
    font-weight: 600;
}

#customize_container .custom_cta_btn_apple:hover {
    background-color: #f0f0f0;
}

.outer_confirmation {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #474747;
}

.streak_icon_in_between {
    width: 100%;
    color: #6019a2;
    font-size: 25px;
}

.terms_container {
    width: 300px;
    /* height: 300px; */
    position: absolute;
    background-color: #ffffff;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1% 2%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .2), 0 30px 50px 0 rgba(0, 0, 0, .5);
}

.terms_container .terms_close_area {
    width: 100%;
    text-align: end;
    font-size: 20px;
    margin-bottom: 10px;
}

.terms_container .terms_close_area svg {
    cursor: pointer;
}

.terms_container .terms_info_area {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.terms_container .terms_info_area span {
    margin-bottom: 10px;
    font-size: 13px;
}

@media (max-width:600px) {
    .custom_login_body .custom_login_body_area {
        width: 100%;
    }

    .custom_login_head {
        flex-direction: column;
        align-items: center;
    }

    .custom_login_head img {
        width: 60%;
        margin-bottom: 2%;
    }

    .custom_login_head .customize_head_forgot {
        width: 70%;
        font-size: 20px;
        padding: 3%;
        margin-bottom: 5%;
    }

    .custom_login_body .custom_login_body_area .custom_login_body_text h2 {
        font-size: 30px;
    }
}