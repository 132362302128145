.task_container {
    background-color: #000000;
    color: #ffffff;
    padding: 3%;
    width: 94%;
    min-height: 100vh;
}

.task_container h1 {
    color: #089A7E;
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
}

.task_container .task_content_area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.task_container .task_content_area h2 {
    font-size: 20px;
    margin: 10px 0px;
}

.task_container .task_content_area p {
    font-size: 14px;
    color: #CECECE;
    text-align: justify;
    margin: 5px 0px;
}