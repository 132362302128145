.privacy_policy_page {
    width: 94%;
    padding: 3%;
}

.privacy_policy_page h1 {
    font-size: 30px;
    margin: 15px 0px;
}

.privacy_policy_page h2 {
    margin: 5px 0px;
}

.privacy_policy_page p {
    margin: 15px 0px;
}