#ticket_login_area {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

#ticket_login_area .ticket_login_head {
    width: 100%;
}

#ticket_login_area .ticket_login_head img {
    width: 40%;
    margin-left: 5%;
}

#ticket_login_area .ticket_login_body {
    width: 100%;
}

#ticket_login_area .ticket_login_body .ticket_login_body_area {
    margin: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#ticket_login_area .ticket_login_body .ticket_login_body_area .ticket_login_body_text {
    color: #2f2f2f;
    text-align: center;
    width: 60%;
}

#ticket_login_area .ticket_login_body .ticket_login_body_area .ticket_login_body_text h2 {
    font-size: 40px;
}

#ticket_login_area .ticket_login_body .ticket_login_body_area .ticket_login_body_text p {
    font-size: 14px;
}

#ticket_login_area .ticket_login_body .ticket_login_body_area .google_area {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 4%;
    margin-bottom: 4%;
}

#ticket_login_area .ticket_login_body .ticket_login_body_area .google_area button {
    width: 80%;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #2f2f2f;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3%;
}

#ticket_login_area .ticket_login_body .ticket_login_body_area .google_area button:hover {
    background-color: #e4e4e4;
}

#ticket_login_area .ticket_login_body .ticket_login_body_area .google_area button span {
    font-size: 20px;
    margin-right: 4%;
}

#ticket_login_area .ticket_login_body .ticket_login_body_area .signup_body_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4%;
}

#ticket_login_area .ticket_login_body .ticket_login_body_area .signup_body_form input {
    width: 74%;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #2f2f2f;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3%;
    margin-bottom: 3%;
    font-size: 20px;
}

#ticket_login_area .ticket_login_body .ticket_login_body_area .signup_body_form button {
    width: 80%;
    background-color: #e64a19;
    border-radius: 5px;
    border: 1px solid #e64a19;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3%;
    margin-bottom: 3%;
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
}

#ticket_login_area .ticket_login_body .ticket_login_body_area .signup_body_form button:hover {
    background-color: #a13d1e;
}

#ticket_view_page {
    background-color: #ffffff;
}

#ticket_view_page .ticket_view_head {
    background-color: #0F2027;
    color: #dddddd;
    padding: 7% 5%;
    border-radius: 0px 0px 0px 0px;
}

#ticket_view_page .ticket_view_head h1 {
    font-size: 40px;
}

#ticket_view_page .ticket_view_head p {
    font-size: 18px;
    font-weight: 100;
}

#ticket_view_page .ticket_view_head p strong {
    font-weight: 400;
    color: #ffffff;
}

#ticket_view_page .ticket_view_body {
    padding: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#ticket_view_page .ticket_view_body .single_ticket_card {
    border: 2px solid #0F2027;
    border-radius: 10px;
    padding: 4%;
    display: flex;
    flex-direction: column;
    color: #1e3945;
    width: 90%;
    margin-top: 2%;
    cursor: pointer;
}

#ticket_view_page .ticket_view_body .single_ticket_card:hover {
    background-color: #FAFAFA;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_card_header .ticket_card_head_id {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_card_header .ticket_card_head_id span {
    color: #787878;
    font-size: 13px;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_card_header .ticket_card_head_id h2 {
    color: #0F2027;
    font-size: 24px;
    font-weight: 700;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_card_header .ticket_card_head_status {
    width: 50%;
    text-align: end;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_card_header .ticket_card_head_status span {
    padding: 2% 7%;
    background-color: #FEBB39;
    font-size: 16px;
    color: #ffffff;
    border-radius: 5px;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_card_header .ticket_card_head_status .pending {
    background-color: #FEBB39;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_card_header .ticket_card_head_status .tech_review {
    background-color: #189341;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_card_header .ticket_card_head_status .awaiting_user_reply {
    background-color: #185DA1;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_card_header .ticket_card_head_status .closed {
    background-color: #B90B0B;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_time {
    width: 100%;
    text-align: left;
    color: #787878;
    font-size: 13px;
    margin-bottom: 3%;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_regarding {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2%;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_regarding p {
    font-size: 13px;
    color: #0F2027;
    font-weight: 700;
    margin-right: 2%;
    width: 80px;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_regarding span {
    margin-left: 2%;
    width: 80px;
    font-size: 13px;
    background-color: #EFEFEF;
    color: #666666;
    text-align: center;
    padding: 5px;
    border: 1px solid #C7C7C7;
    border-radius: 5px;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_regarding .blockerx_app {
    background-color: #FDE0D7;
    color: #E64919;
    border: 1px solid #FC8B69;
} 

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_regarding .healify_app {
    background-color: #EDFCDE;
    color: #61A945;
    border: 1px solid #95CA60;
} 

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_regarding .zenze_app {
    background-color: #E4F9F1;
    color: #3BB78B;
    border: 1px solid #91E3C6;
} 

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_regarding .socialx_app {
    background-color: #E3EEFF;
    color: #0C47A1;
    border: 1px solid #89A9DA;
} 

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_regarding .dtox_app {
    background-color: #E6FFCD;
    color: #3BB78B;
    border: 1px solid #91E3C6;
} 

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_category {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_category p {
    font-size: 13px;
    color: #0F2027;
    font-weight: 700;
    margin-right: 2%;
    width: 80px;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_category span {
    margin-left: 2%;
    width: 80px;
    font-size: 13px;
    background-color: #ECF6FF;
    color: #185DA1;
    text-align: center;
    padding: 5px;
    border: 1px solid #6AA9E7;
    border-radius: 5px;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_description {
    width: 100%;
    display: flex;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    padding: 3% 0%;
    margin: 3% 0%;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_description p {
    width: 25%;
    color: #185DA1;
    font-size: 13px;
    font-weight: 700;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_description span {
    width: 75%;
    color: #787878;
    font-size: 13px;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_note {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

#ticket_view_page .ticket_view_body .single_ticket_card .single_ticket_note p {
    text-align: right;
    font-size: 13px;
    color: #185DA1;
}

/* #ticket_view_page .ticket_view_body .single_ticket_card strong {
    color: #0F2027;
}

#ticket_view_page .ticket_view_body .single_ticket_card p {
    text-align: right;
    font-size: 13px;
    border-top: 1px dotted #0F2027;
    margin-top: 5%;
} */

#ticket_single_page {
    height: 100vh;
    overflow: hidden;
    background-color: #ffffff;
    white-space: pre-line;
}

#ticket_single_page .single_ticket_header {
    background-color: #0F2027;
    padding: 5%;
}

#ticket_single_page .single_ticket_header .single_ticket_brand {
    width: 100%;
    margin-bottom: 3%;
}

#ticket_single_page .single_ticket_header .single_ticket_brand img {
    width: 50%;
}

#ticket_single_page .single_ticket_header .single_ticket_user {
    width: 100%;
    text-align: left;
    margin-bottom: 3%;
}

#ticket_single_page .single_ticket_header .single_ticket_user span {
    background-color: #ffffff;
    color: #0F2027;
    padding: 2% 5%;
    border-radius: 30px;
}

#ticket_single_page .single_ticket_body {
    padding: 5%;
    height: 60vh;
    overflow-y: scroll;
}

#ticket_single_page .single_ticket_body h1 {
    text-align: center;
}

#ticket_single_page .single_ticket_body .single_ticket_info {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    margin-bottom: 2%;
}

#ticket_single_page .single_ticket_body .single_ticket_info .ticket_files_list .single_file_icon {
    margin-left: 5px;
    font-size: 20px;
    color: #0F2027;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation {
    overflow-wrap: break-word;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .user_type {
    width: 98%;
    padding: 3% 1%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-bottom: 1%;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .user_type img {
    width: 12%;
    object-fit: contain;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .user_type p {
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    color: #bababa;
    padding: 3%;
    max-width: 60%;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: right;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .user_type p span {
    font-size: 15px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 5px;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .user_type p .attached_image_container {
    width: 100%;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .user_type p .attached_image_container .potrait {
    width: auto;
    height: 100%;
    object-fit: contain;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .user_type p .attached_image_container .landscape {
    width: 100%;
    height: auto;
    object-fit: contain;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .user_type p .attached_image_container video {
    width: 100%;
    object-fit: contain;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .user_type p .sub_message {
    color: #bababa;
    font-size: 12px;
    text-align: right;
    margin-bottom: 0px;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type {
    width: 98%;
    padding: 3% 1%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1%;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type img {
    width: 12%;
    object-fit: contain;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type p {
    background: #e6e6e6;
    color: #3d5963;
    border: 1px solid #ffffff;
    padding: 3%;
    max-width: 60%;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type p span {
    font-size: 15px;
    text-align: left;
    color: #0e2c36;
    font-weight: 500;
    margin-bottom: 5px;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type p .attached_image_container {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type p .attached_image_container .potrait {
    width: auto;
    height: 100%;
    object-fit: contain;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type p .attached_image_container .landscape {
    width: 100%;
    height: auto;
    object-fit: contain;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type p .attached_image_container video {
    width: 100%;
    object-fit: contain;
}

#ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type p .sub_message {
    color: #3d5963;
    font-size: 12px;
    text-align: left;
    margin-bottom: 0px;
}

#ticket_single_page .single_ticket_footer {
    position: fixed;
    bottom: 0;
    background-color: #0F2027;
    padding: 5%;
    width: 90%;
    display: flex;
    align-items: center;
}

#ticket_single_page .single_ticket_footer .ticket_reply_attach {
    margin-right: 5px;
}

#ticket_single_page .single_ticket_footer .ticket_reply_attach button {
    background: transparent;
    border: none;
    outline: none;
    color: #ffffff;
    font-size: 25px;
    cursor: pointer;
}

#ticket_single_page .single_ticket_footer .ticket_reply_footer {
    width: 80%;
} 

#ticket_single_page .single_ticket_footer .ticket_reply_footer input {
    background: transparent;
    font-size: 20px;
    border: none;
    border-bottom: 2px solid #1e3945;
    color: #ffffff;
    width: 90%;
}

#ticket_single_page .single_ticket_footer .ticket_reply_footer input:focus, #ticket_single_page .single_ticket_footer .ticket_reply_footer input:active {
    outline: none;
    border: none;
    border-bottom: 2px solid #355b6c;
}

#ticket_single_page .single_ticket_footer .ticket_reply_submit {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ticket_single_page .single_ticket_footer .ticket_reply_submit button {
    width: 50px;
    height: 50px;
    font-size: 25px;
    background-color: #ffffff;
    color: #0F2027;
    border-radius: 50%;
    border: none;
}

#ticket_single_page .single_ticket_footer .ticket_reply_submit button img {
    width: 100%;
}

#ticket_single_page .ticket_attach_popup {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #54545463;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
    word-wrap: break-word;
}

#ticket_single_page .ticket_attach_popup .ticket_attach_container {
    background-color: #ffffff;
    width: 80%;
    padding: 5%;
    border-radius: 5px;
    text-align: center;
}

#ticket_single_page .ticket_attach_popup .ticket_attach_container .file_attach_close_area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    color: #0F2027;
    margin-bottom: 2%;
}

#ticket_single_page .ticket_attach_popup .ticket_attach_container .file_attach_container {
    width: 100%;
    text-align: left;
    margin-top: 2%;
    margin-bottom: 2%;
}

#ticket_single_page .ticket_attach_popup .ticket_attach_container .file_attach_container .upload_this_file_btn {
    background-color: #0F2027;
    color: #ffffff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 3% 5%;
    border-radius: 5px;
    font-size: 18px;
    margin-top: 2%;
}

@media (min-width: 1000px) {
    #ticket_single_page .single_ticket_header {
        background-color: #0F2027;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2% 5%;
        height: 10vh;
        width: 90%;
    }
    
    #ticket_single_page .single_ticket_header .single_ticket_brand {
        width: 15%;
        margin-bottom: 0%;
    }
    
    #ticket_single_page .single_ticket_header .single_ticket_brand img {
        width: 100%;
    }
    
    #ticket_single_page .single_ticket_header .single_ticket_user {
        width: auto;
        margin-bottom: 0%;
    }
    
    #ticket_single_page .single_ticket_header .single_ticket_user span {
        background-color: #ffffff;
        color: #0F2027;
        padding: 3% 7%;
        border-radius: 30px;
        font-size: 30px;
    }
    
    #ticket_single_page .single_ticket_body {
        padding: 5%;
        height: 60vh;
        overflow-y: scroll;
    }
    
    #ticket_single_page .single_ticket_body h1 {
        text-align: center;
    }
    
    #ticket_single_page .single_ticket_body .single_ticket_info {
        display: flex;
        flex-direction: column;
        margin-top: 2%;
        margin-bottom: 2%;
    }
    
    /* #ticket_single_page .single_ticket_body .single_ticket_conversation {
        height: 25vh;
        overflow-y: scroll;
    } */
    
    #ticket_single_page .single_ticket_body .single_ticket_conversation .user_type {
        width: 98%;
        padding: 3% 1%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        margin-bottom: 1%;
    }
    
    #ticket_single_page .single_ticket_body .single_ticket_conversation .user_type img {
        width: 5%;
        object-fit: contain;
    }
    
    #ticket_single_page .single_ticket_body .single_ticket_conversation .user_type p {
        background: #0F2027;
        background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
        background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
        color: #bababa;
        padding: 1% 2%;
        max-width: 60%;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        text-align: right;
    }
    
    #ticket_single_page .single_ticket_body .single_ticket_conversation .user_type p span {
        font-size: 15px;
        text-align: left;
        color: #ffffff;
        margin-bottom: 5px;
    }

    #ticket_single_page .single_ticket_body .single_ticket_conversation .user_type p .attached_image_container {
        height: 350px;
    }

    #ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type p .attached_image_container {
        height: 350px;
    }
    
    #ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type {
        width: 98%;
        padding: 1% 1%;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 1%;
    }
    
    #ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type img {
        width: 5%;
        object-fit: contain;
    }
    
    #ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type p {
        background: #e6e6e6;
        color: #3d5963;
        border: 1px solid #ffffff;
        padding: 1% 2%;
        max-width: 60%;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    
    #ticket_single_page .single_ticket_body .single_ticket_conversation .dev_type p span {
        font-size: 15px;
        text-align: left;
        color: #0e2c36;
        font-weight: 500;
        margin-bottom: 5px;
    }
    
    #ticket_single_page .single_ticket_footer {
        position: fixed;
        bottom: 0;
        background-color: #0F2027;
        padding: 1% 5%;
        width: 90%;
        display: flex;
        align-items: center;
        height: 10vh;
    }
    
    #ticket_single_page .single_ticket_footer .ticket_reply_footer {
        width: 80%;
    } 
    
    #ticket_single_page .single_ticket_footer .ticket_reply_footer input {
        background: transparent;
        font-size: 20px;
        border: none;
        border-bottom: 2px solid #1e3945;
        color: #ffffff;
        width: 90%;
    }
    
    #ticket_single_page .single_ticket_footer .ticket_reply_footer input:focus, #ticket_single_page .single_ticket_footer .ticket_reply_footer input:active {
        outline: none;
        border: none;
        border-bottom: 2px solid #355b6c;
    }
    
    #ticket_single_page .single_ticket_footer .ticket_reply_submit {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    #ticket_single_page .single_ticket_footer .ticket_reply_submit button {
        width: 50px;
        height: 50px;
        font-size: 25px;
        background-color: #ffffff;
        color: #0F2027;
        border-radius: 50%;
        border: none;
    }
    
    #ticket_single_page .single_ticket_footer .ticket_reply_submit button img {
        width: 100%;
    }

    #ticket_view_page .ticket_view_head {
        padding: 2% 5%;
    }
    
    #ticket_view_page .ticket_view_head p strong {
        font-weight: 400;
        color: #ffffff;
    }
    
    #ticket_view_page .ticket_view_body {
        padding: 1% 4%;
    }
    
    #ticket_view_page .ticket_view_body .single_ticket_card {
        border-radius: 10px;
        padding: 2% 3%;
        width: 90%;
        margin-top: 2%;
    }
    
    /* #ticket_view_page .ticket_view_body .single_ticket_card strong {
        color: #0F2027;
    }

    #ticket_view_page .ticket_view_body .single_ticket_card p {
        margin-top: 2%;
    } */
    
}