.artticleContent {
    scroll-behavior: smooth;
}

/* Nav bar */
.navbar img {
    height: 25px;
}

.navigation-menu {
    display: flex;
    align-items: center;
    list-style: none;
}

.navigation-menu li {
    margin-left: 20px;
}

.navigation-menu li a {
    text-decoration: none;
    color: black;
    font-size: 1rem;
    text-transform: capitalize;
}

.navigation-menu li.active a {
    text-decoration: underline;
}

.products-nav {
    display: flex;
    align-items: center;
    justify-content: center;
}

.products-nav a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    font-size: 1rem;
    text-transform: capitalize;
}

.navigation-mobile li {
    list-style: none;
    text-decoration: none;
    font-size: 1rem;
    color: black; 
    margin-top: 15px;
    margin-left: -30px;
}

.navigation-mobile li a {
    text-decoration: none;
    color: black;
    text-transform: capitalize;
}

.navigation-mobile li.active a {
    text-decoration: underline;
}

.articleHeading {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}   

.contentContainer .tableContent li {
    cursor: pointer;
    text-decoration: underline;
    color: #E24B1C;
    margin-top: 2px;
    font-family: poppins;
    align-items: baseline;
    margin-left: -20px;  
}

.contentContainer .tableContent li ul {
    margin-left: -10px; 
}

.contentContainer .tableContent li a {
    cursor: pointer;
    text-decoration: underline;
    color: #E24B1C;
    margin-top: 2px;
    font-family: poppins;
    align-items: baseline;
}

.heading1 {
    font-weight: 700;
    font-family: poppins;
    color: #3E3E3E;
    padding-bottom: 15px;
}

.articleHeading .frameImage {
    /* height: 60%; */
    width: 60%;
    align-self: flex-end;
    margin: 10px; 
} 

.articleHeadline {
    margin: 20px;
    color: white;
} 

.name {
    margin-top: 40px;
}

.profile img {
    border-radius: 50%;
    width: 90px;
    height: 90px;
}
