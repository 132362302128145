.course_window {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    color: #252525;
}

.course_window .bootcamp_main_header {
    width: 92%;
    height: 7vh;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2% 4%;
    color: #000000;
    position: fixed;
    top: 0px;
    z-index: 3;
}

.course_window .bootcamp_main_header button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #0A84FF;
    background: transparent;
    border: none;
    outline: none;
    font-size: 18px;
}

.course_window .bootcamp_main_header button svg {
    margin-right: 10px;
}   

.course_window .bootcamp_main_header button img {
    width: 50px;
}

.course_window .bootcamp_main_header h2 {
    font-size: 18px;
    margin-right: 10px;
}

.course_window .bootcamp_progress {
    width: 100%;
    max-width: 500px;
    margin-top: 7vh;
    min-height: 93vh;
    background-color: #ffffff;
}

.course_window .bootcamp_progress .single_session {
    width: 94%;
    padding: 3%;
    margin-top: 5%;
    background-color: #ffffff;
}

.course_window .bootcamp_progress .single_session .session_header {
    width: 92%;
    background: linear-gradient(93.94deg, #E34B13 3.85%, #FFBD14 97.25%);
    color: #ffffff;
    padding: 3% 4%;
    border-radius: 10px;
    margin-bottom: 50px;
}

.course_window .bootcamp_progress .single_session .session_header h2 {
    font-size: 18px;
    font-weight: 200;
}

.course_window .bootcamp_progress .single_session .session_header h1 {
    font-size: 20px;
}

.course_window .bootcamp_progress .single_session .session_body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.course_window .bootcamp_progress .single_session .session_body .session_element {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    background-color: #ffffff;
}

.course_window .bootcamp_progress .single_session .session_body .session_element .session_button_area {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.course_window .bootcamp_progress .single_session .session_body .session_element .session_button_area button {
    transform: rotateX(35deg);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #E3E3E3;
    border: 5px solid #CBCBCB;
    box-shadow: #B7B7B7 0px 10px;
    cursor: pointer;
    z-index: 2;
}

.course_window .bootcamp_progress .single_session .session_body .session_element .session_button_area .completed_element {
    background-color: #3DC66C;
    border: 5px solid #28AB55;
    box-shadow: #218844 0px 10px;
}

.course_window .bootcamp_progress .single_session .session_body .session_element .session_button_area .next_element {
    background-color: #E34B13;
    border: 5px solid #C65126;
    box-shadow: #AC3B11 0px 10px;
}

.course_window .bootcamp_progress .single_session .session_body .session_element .horizontal_dotted_line {
    border-bottom: 10px dotted #E3E3E3;
    width: 150px;
    position: absolute;
    transform: translate(-100px, 50px) rotate(-25deg);
    z-index: 1;
}

.course_window .bootcamp_progress .single_session .session_body .session_element .horizontal_dotted_line_reverse {
    border-bottom: 10px dotted #E3E3E3;
    width: 180px;
    position: absolute;
    transform: translate(100px, 50px) rotate(25deg);
    z-index: 1;
}

.next-button {
    position: absolute;
    display: inline-block;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #F4B000; 
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    z-index: 2;
    transform: translate(-28px, -90px);
    animation: bounce2 2s ease infinite;
}

/* Triangle at the bottom */
.next-button::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #F4B000;
}

@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translate(-28px, -90px);}
	40% {transform: translate(-28px, -120px);}
	60% {transform: translate(-28px, -135px)}
}

.course_window .lost_screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%
}

.course_window .lost_screen img {
    width: 80%;
}

.course_window .scroll_to_current_module {
    position: fixed;
    z-index: 4;
    bottom: 20px;
    right: 20px;
    background: #000000;
    padding: 3%;
    border: none;
    outline: none;
    border-radius: 10px;
    color: #ffffff;
    font-size: 18px;
}

.course_window .scroll_to_current_module svg {
    margin-left: 10px;
}

.course_window .video_content_area {
    width: 100%;
    overflow: hidden;
    height: 90vh;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.course_window .video_content_area .video_content_info {
    height: 80%;
    overflow-y: scroll;
}

.course_window .video_content_area .article_content_info {
    width: 100%;
    height: 80%;
    overflow-y: scroll;
}

.course_window .video_content_area .question_content_info {
    width: 100%;
    height: 80%;
    overflow-y: scroll;
}

.course_window .video_content_area .video_content_info .video_thumbnail_area {
    width: 100%;
}

.course_window .video_content_area .video_content_info .video_thumbnail_area img {
    width: 100%;
}

.course_window .video_content_area .video_content_info h2 {
    width: 92%;
    padding: 4%;
}

.course_window .video_content_area .video_content_info .video_extra_info {
    width: 92%;
    padding: 0% 4%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.course_window .video_content_area .video_content_info .video_extra_info span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    color: #E34B13;
}

.course_window .video_content_area .video_content_info .video_extra_info span svg {
    margin-right: 5px;
}

.course_window .video_content_area .video_content_info p {
    width: 92%;
    padding: 4%;
    font-size: 15px;
}

.course_window .video_content_area .video_action_area {
    width: 100%;
    padding-bottom: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.course_window .video_content_area .video_action_area button {
    background: linear-gradient(90deg, #E34B13 0%, #FD8A02 100%);
    padding: 4%;
    width: 90%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border: none;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
}

.course_window .video_content_area .video_action_area button svg {
    margin-right: 10px;
}

.course_window .video_content_area .question_content_info h1 {
    font-size: 32px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.course_window .video_content_area .question_content_info .question_progress_container {
    width: 92%;
    padding: 4%;
}

.course_window .video_content_area .question_content_info .question_progress_container h3 {
    font-size: 20px;
    color: #5F6368;
    font-weight: 400;
}

.course_window .video_content_area .question_content_info .question_progress_container h3 strong {
    font-weight: 600;
    color: #E34B13;
    font-size: 24px;
}

.course_window .video_content_area .question_content_info .question_progress_container .progress_div_outer {
    width: 100%;
    height: 10px;
    background-color: #F3F3F3;
    border-radius: 100px;
    overflow: hidden;
    margin-bottom: 20px;
}

.course_window .video_content_area .question_content_info .question_progress_container .progress_div_outer .progress_div_inner {
    height: 10px;
    background: linear-gradient(90deg, #E34B13 0%, #EB6B3C 100%);
    border-radius: 100px;
}

.course_window .video_content_area .question_content_info p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
    width: 92%;
    padding: 0% 4%;
}

.course_window .video_content_area .question_content_info .questions_options {
    width: 92%;
    padding: 0% 4%;
}

.course_window .video_content_area .question_content_info .questions_options .question_single_option {
    background-color: #F3F3F3;
    padding: 4%;
    margin-bottom: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    border-radius: 7px;
}

.course_window .video_content_area .question_content_info .questions_options .question_single_option svg {
    margin-right: 10px;
}

.video_action_area .no_answer_result {
    display: flex;
    color: #AC3B11;
    width: 100%;
    padding: 4%;
    margin-left: 50px;
    align-items: center;
    justify-content: flex-start;
}

.video_action_area .no_answer_result img {
    width: 20px;
    object-fit: contain;
    margin-right: 10px;
}

.video_action_area .correct_answer_result {
    align-self: flex-start;
    display: flex;
    background-color: #D8FFE5;
    color: #218844;
    width: 30%;
    padding: 2%;
    margin-left: 20px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    font-size: 17px;
}

.video_action_area .correct_answer_result img {
    width: 30px;
    object-fit: contain;
    margin-right: 7px;
}

.video_action_area .incorrect_answer_result {
    align-self: flex-start;
    display: flex;
    background-color: #FFD8D8;
    color: #CA2121;
    width: 30%;
    padding: 2%;
    margin-left: 20px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    font-size: 17px;
}

.video_action_area .incorrect_answer_result img {
    width: 30px;
    object-fit: contain;
    margin-right: 7px;
}

.video_content_info .books_container {
    margin: 5% 0%;
    width: 100%;
}

.video_content_info .books_container .single_chapter_book {
    width: 92%;
    padding: 4%;
}

.video_content_info .books_container .single_chapter_book h3 {
    text-align: center;
    font-size: 16px;
    color: #5F6368;
}

.video_content_info .books_container .single_chapter_book h2 {
    text-align: center;
    font-size: 20px;
    color: #000000;
}

.video_content_info .books_container .single_chapter_book .heading1 {
    font-size: 16px;
    color: #000000;
    text-align: left;
    margin-left: 4%;
}

.video_content_info .books_container .single_chapter_book .heading2 {
    color: #5B5B5B;
    font-size: 14px;
    text-align: left;
    margin-left: 4%;
}

.video_content_info .books_container .single_chapter_book .paragraph {
    color: #5B5B5B;
    font-size: 14px;
    text-align: left;
}

.video_content_info .books_container .single_chapter_book .paragraph2 {
    color: #5B5B5B;
    font-size: 14px;
    text-align: left;
    font-style: italic;
}

.video_content_info .books_container .single_chapter_book .paragraph3 {
    color: #5B5B5B;
    font-size: 14px;
    text-align: left;
}

.video_content_info .books_container .single_chapter_book img {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.video_content_info .books_container .single_chapter_book .bulletPointer {
    font-size: 13px;
}

.video_content_info .books_container .single_chapter_book .numPointer {
    font-size: 13px;
}