.main_block_window {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    background-color: #FFE4DB;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main_block_window .blocked_content_area {
    width: 37%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main_block_window .blocked_content_area .blocked_by_logo {
    width: 40%;
    margin-bottom: 50px;
}

.main_block_window .blocked_content_area .blocked_by_logo img {
    width: 100%;
}

.main_block_window .blocked_content_area .blocked_by_heading {
    /* width: 90%; */
    font-size: 20px;
    text-align: center;
}

.main_block_window .blocked_content_area .blocked_by_message {
    width: 90%;
    text-align: center;
    margin-bottom: 50px;
} 

.main_block_window .blocked_content_area .blocked_by_actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main_block_window .blocked_content_area .blocked_by_actions .blocked_primary_btn {
    width: 30%;
    padding: 2%;
    background-color: #e64a19;
    color: #ffffff;
    border: none;
    outline: none;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
}

.main_block_window .blocked_content_area .blocked_by_actions .blocked_secondary_btn {
    background: transparent;
    border: none;
    outline: none;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}